import { object, string, ref } from 'yup';
import { useTranslation } from 'react-i18next';

export const useValidateFields = () => {
  const { t } = useTranslation();

  const signUp = object().shape({
    email: string().trim().email(t('inputValidation.email')).required(t('inputValidation.required')),
    /* password: string()
      .trim()
      .required(t('inputValidation.required'))
      .matches(/^.{8,40}$/, t('inputValidation.password')), */
  });

  const setPassword = object().shape({
    password: string()
      .trim()
      .required(t('inputValidation.required'))
      .matches(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?!.*\s).{8,40}$/, t('inputValidation.password')),
    confirmPassword: string()
      .trim()
      .oneOf([ref('password'), ''], t('inputValidation.match'))
      .required(t('inputValidation.required')),
  });

  return { signUp, setPassword };
};
