import React from 'react';
import PropTypes from 'prop-types';

import { useLocalStorage } from 'utils/hooks/useLocalStorage';
import { CONDITIONS, KEYS } from '../utils/constants';

import { PregnancyBenefits } from './PregnancyBenefits';
import { PostpartumBenefits } from './PostpartumBenefits';
import { PainBenefits } from './PainBenefits';

const Benefits = ({ path }) => {
  const [selectedPath] = useLocalStorage(KEYS.PATH);

  switch (path || selectedPath) {
    case CONDITIONS.PREGNANCY:
      return <PregnancyBenefits />;
    case CONDITIONS.POSTPARTUM:
      return <PostpartumBenefits />;
    case CONDITIONS.INCONTINENCE:
    case CONDITIONS.SEXUAL:
      return <PainBenefits />;
    default:
      return null;
  }
};

Benefits.defaultProps = {
  path: null,
};

Benefits.propTypes = {
  path: PropTypes.string,
};

export default Benefits;
