import React from 'react';
import { Trans } from 'react-i18next';

import cardBrands from 'assets/images/card-brand.webp';

const Warranty = () => (
  <>
    <p className="plan-screen__offerings_guarantee">
      <Trans components={{ strong: <strong /> }}>evieOnboarding.planScreen.offering.warranty</Trans>
    </p>
    <div className="plan-screen__offerings_cardbrands">
      <img
        src={cardBrands}
        alt="card brands: Visa, Mastercard, Maestro"
        className="plan-screen__offerings_cardbrands"
      />
    </div>
    <p className="plan-screen__offerings_guarantee terms">
      <Trans components={{ strong: <strong /> }}>evieOnboarding.planScreen.offering.terms</Trans>
    </p>
  </>
);

<div>Warranty</div>;

export default Warranty;
