import React from 'react';
import PropTypes from 'prop-types';

import hearBubble from 'assets/images/heart-bubble.webp';

export const BenefitCard = ({ children }) => (
  <section className="index-screen__benefitcard">
    <div className="index-screen__benefitcard_icon">
      <img src={hearBubble} alt="heart-bubble icon" />
    </div>
    {children}
  </section>
);

BenefitCard.propTypes = {
  children: PropTypes.node.isRequired,
};
