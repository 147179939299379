import React from 'react';
import PropTypes from 'prop-types';

const HeroIndex = ({ children, isDiscount }) => (
  <div className={`index-screen__hero ${isDiscount ? 'discount' : ''}`}>{children}</div>
);

HeroIndex.defaultProps = { isDiscount: false };

HeroIndex.propTypes = {
  children: PropTypes.node.isRequired,
  isDiscount: PropTypes.bool,
};

export default HeroIndex;
