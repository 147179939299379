import { useHistory } from 'react-router-dom';

import { queryParams } from 'utils/string';
import { convertType } from './helpers';

export const useGetParams = () => {
  const {
    goBack,
    push,
    location: { search },
  } = useHistory();
  const {
    step = 0,
    group = 1,
    question = 1,
    source = null,
    plan_step: planStep = 1,
    completed = 'false',
    token = '',
    identifier = null,
  } = queryParams(search);

  const urlParams = new URLSearchParams(search).toString();

  const stepNumber = convertType(step);
  const groupNumber = convertType(group);
  const questionNumber = convertType(question);
  const planStepNumber = convertType(planStep);

  return {
    urlParams,
    step: stepNumber,
    group: groupNumber,
    question: questionNumber,
    source,
    planStep: planStepNumber,
    completed,
    urlToken: token,
    identifier,
    push,
    goBack,
  };
};
