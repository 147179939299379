import React from 'react';
import PropTypes from 'prop-types';

const InfoData = ({ children }) => <section className="index-screen__infodata">{children}</section>;

InfoData.propTypes = {
  children: PropTypes.node.isRequired,
};

export default InfoData;
