/* eslint-disable import/no-unresolved */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay } from 'swiper/modules';
import { FaStar } from 'react-icons/fa';
import 'react-lazy-load-image-component/src/effects/opacity.css';
import 'swiper/css';

import linearGradientBg from 'assets/images/linear-gradient-bg.webp';
import luisa from 'assets/images/caro.webp';
import pao from 'assets/images/pao.webp';
import anna from 'assets/images/anna.webp';
import star from 'assets/images/evie-star.svg';

export const PainSlides = () => {
  const { t } = useTranslation();

  return (
    <section className="index-screen__testimonials">
      <div className="index-screen__testimonials_title">
        <div className="index-screen__testimonials_titleicon">
          <img src={star} alt="Star" />
        </div>
        <h2 className="index-screen__testimonials_titlelabel">{t('evieOnboarding.planScreen.testimonials.title')}</h2>
      </div>
      <div className="index-screen__testimonials_bg" />
      <Swiper speed={600} slidesPerView={1} autoplay={{ delay: 4000 }} modules={[Autoplay]} loop>
        <SwiperSlide>
          <section className="info-screen__testimonials index-view">
            <div className="info-screen__testimonial index-view">
              <div>
                <FaStar className="info-screen__testimonial_star" />
                <FaStar className="info-screen__testimonial_star" />
                <FaStar className="info-screen__testimonial_star" />
                <FaStar className="info-screen__testimonial_star" />
                <FaStar className="info-screen__testimonial_star" />
              </div>
              <p className="info-screen__testimonial_content">
                {t('evieOnboarding.planScreen.testimonials.pain.luisa.content')}
              </p>
              <p className="info-screen__testimonial_author">
                {t('evieOnboarding.planScreen.testimonials.pain.luisa.author')}
              </p>
            </div>
            <img className="info-screen__bgimg testimonialbg" src={linearGradientBg} alt="linear gradient background" />
            <img className="info-screen__bgimg info-screen__testimonials_img" src={luisa} alt="Woman" />
          </section>
        </SwiperSlide>
        <SwiperSlide>
          <section className="info-screen__testimonials index-view">
            <div className="info-screen__testimonial index-view">
              <div>
                <FaStar className="info-screen__testimonial_star" />
                <FaStar className="info-screen__testimonial_star" />
                <FaStar className="info-screen__testimonial_star" />
                <FaStar className="info-screen__testimonial_star" />
                <FaStar className="info-screen__testimonial_star" />
              </div>
              <p className="info-screen__testimonial_content">
                {t('evieOnboarding.planScreen.testimonials.pain.pao.content')}
              </p>
              <p className="info-screen__testimonial_author">
                {t('evieOnboarding.planScreen.testimonials.pain.pao.author')}
              </p>
            </div>
            <img className="info-screen__bgimg testimonialbg" src={linearGradientBg} alt="linear gradient background" />
            <img className="info-screen__bgimg info-screen__testimonials_img" src={pao} alt="Woman" />
          </section>
        </SwiperSlide>
        <SwiperSlide>
          <section className="info-screen__testimonials index-view">
            <div className="info-screen__testimonial index-view">
              <div>
                <FaStar className="info-screen__testimonial_star" />
                <FaStar className="info-screen__testimonial_star" />
                <FaStar className="info-screen__testimonial_star" />
                <FaStar className="info-screen__testimonial_star" />
                <FaStar className="info-screen__testimonial_star" />
              </div>
              <p className="info-screen__testimonial_content">
                {t('evieOnboarding.planScreen.testimonials.pain.anna.content')}
              </p>
              <p className="info-screen__testimonial_author">
                {t('evieOnboarding.planScreen.testimonials.pain.anna.author')}
              </p>
            </div>
            <img className="info-screen__bgimg testimonialbg" src={linearGradientBg} alt="linear gradient background" />
            <img className="info-screen__bgimg info-screen__testimonials_img" src={anna} alt="Woman" />
          </section>
        </SwiperSlide>
      </Swiper>
      <div className="index-screen__testimonials_whitewavebg" />
      <div className="index-screen__testimonials_whitewavebg bottom" />
    </section>
  );
};
