import React from 'react';
import { isEmpty } from 'ramda';

import { useConversationsContext } from 'components/conversations/ConversationsProvider';
import { useGetParams } from 'components/evieOnboarding/utils/useGetParams';
import { ROUTES } from 'utils/constants';
import { getFullDate, getNameAndInitials } from '../utils';

const ConversationHeader = () => {
  const { push } = useGetParams();
  const { selectedConversation, lng, isOpenModal, handleModal } = useConversationsContext();
  const isNotSelectedConversation = isEmpty(selectedConversation);

  const { attributes: { employee_id: userId = '' } = {} } = selectedConversation;

  const dateUptaded = isNotSelectedConversation
    ? null
    : getFullDate({
        date: selectedConversation.dateUpdated,
        lng,
        options: { month: 'short', day: 'numeric', year: 'numeric' },
      });

  const { name, initials } = getNameAndInitials(selectedConversation);

  const handleGoToProfile = () => {
    if (isOpenModal) {
      handleModal();
    }
    push(`${ROUTES.INDIVIDUALS}/${userId}`);
  };

  return (
    <>
      <div className="initials">
        <span>{initials}</span>
      </div>
      <button type="button" className="credentials" onClick={handleGoToProfile}>
        <h4>{name}</h4>
        <p>
          <span>{dateUptaded}</span>
        </p>
      </button>
    </>
  );
};

export default ConversationHeader;
