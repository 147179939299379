import React from 'react';
import { useTranslation } from 'react-i18next';

import { GoalsCard } from './GoalsCard';

export const PostpartumGoalsCards = () => {
  const { t } = useTranslation();

  return (
    <section className="index-screen__goals">
      <GoalsCard icon="sad">
        <p className="index-screen__goalscard_tag">{t('evieOnboarding.indexScreen.goals.postpartum.nowCard.tag')}</p>
        <div className="index-screen__cardlabel first">
          <p className="index-screen__cardlabel_key">{t('evieOnboarding.indexScreen.goals.postpartum.nowCard.key1')}</p>
          <p className="index-screen__cardlabel_value">
            {t('evieOnboarding.indexScreen.goals.postpartum.nowCard.value1')}
          </p>
        </div>
        <div className="index-screen__cardlabel">
          <p className="index-screen__cardlabel_key">{t('evieOnboarding.indexScreen.goals.postpartum.nowCard.key2')}</p>
          <p className="index-screen__cardlabel_value">
            {t('evieOnboarding.indexScreen.goals.postpartum.nowCard.value2')}
          </p>
          <span className="index-screen__cardlabel_rate" />
          <span className="index-screen__cardlabel_rate empty" />
          <span className="index-screen__cardlabel_rate empty" />
          <span className="index-screen__cardlabel_rate empty" />
        </div>
        <div className="index-screen__cardlabel">
          <p className="index-screen__cardlabel_key">{t('evieOnboarding.indexScreen.goals.postpartum.nowCard.key3')}</p>
          <p className="index-screen__cardlabel_value">
            {t('evieOnboarding.indexScreen.goals.postpartum.nowCard.value3')}
          </p>
          <span className="index-screen__cardlabel_rate" />
          <span className="index-screen__cardlabel_rate empty" />
          <span className="index-screen__cardlabel_rate empty" />
          <span className="index-screen__cardlabel_rate empty" />
        </div>
      </GoalsCard>
      <GoalsCard icon="happy">
        <p className="index-screen__goalscard_tag">{t('evieOnboarding.indexScreen.goals.postpartum.goalCard.tag')}</p>
        <div className="index-screen__cardlabel first">
          <p className="index-screen__cardlabel_key">
            {t('evieOnboarding.indexScreen.goals.postpartum.goalCard.key1')}
          </p>
          <p className="index-screen__cardlabel_value">
            {t('evieOnboarding.indexScreen.goals.postpartum.goalCard.value1')}
          </p>
        </div>
        <div className="index-screen__cardlabel">
          <p className="index-screen__cardlabel_key">
            {t('evieOnboarding.indexScreen.goals.postpartum.goalCard.key2')}
          </p>
          <p className="index-screen__cardlabel_value">
            {t('evieOnboarding.indexScreen.goals.postpartum.goalCard.value2')}
          </p>
          <span className="index-screen__cardlabel_rate" />
          <span className="index-screen__cardlabel_rate" />
          <span className="index-screen__cardlabel_rate" />
          <span className="index-screen__cardlabel_rate empty" />
        </div>
        <div className="index-screen__cardlabel">
          <p className="index-screen__cardlabel_key">
            {t('evieOnboarding.indexScreen.goals.postpartum.goalCard.key3')}
          </p>
          <p className="index-screen__cardlabel_value">
            {t('evieOnboarding.indexScreen.goals.postpartum.goalCard.value3')}
          </p>
          <span className="index-screen__cardlabel_rate" />
          <span className="index-screen__cardlabel_rate" />
          <span className="index-screen__cardlabel_rate" />
          <span className="index-screen__cardlabel_rate" />
        </div>
      </GoalsCard>
    </section>
  );
};
