import React from 'react';

import UnitCard from '../UnitCard';

const ProgramContentUnits = () => (
  <section className="program-content">
    <UnitCard />
    <UnitCard />
    <UnitCard />
    <UnitCard />
    <UnitCard />
    <UnitCard />
  </section>
);

export default ProgramContentUnits;
