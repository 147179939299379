import React from 'react';
import PropTypes from 'prop-types';

import { useLocalStorage } from 'utils/hooks/useLocalStorage';
import { CONDITIONS, KEYS } from '../utils/constants';

import { PregnancySlides } from './PregnancySlides';
import { PostpartumSlides } from './PostpartumSlides';
import { PainSlides } from './PainSlides';

const Testimonials = ({ path }) => {
  const [selectedPath] = useLocalStorage(KEYS.PATH);

  switch (path || selectedPath) {
    case CONDITIONS.PREGNANCY:
      return <PregnancySlides />;
    case CONDITIONS.POSTPARTUM:
      return <PostpartumSlides />;
    case CONDITIONS.INCONTINENCE:
    case CONDITIONS.SEXUAL:
      return <PainSlides />;
    default:
      return null;
  }
};

Testimonials.defaultProps = {
  path: null,
};

Testimonials.propTypes = {
  path: PropTypes.string,
};

export default Testimonials;
