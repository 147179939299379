import React from 'react';
import { useTranslation, Trans } from 'react-i18next';

import tagUser from 'assets/images/tag-user.webp';
import medalStar from 'assets/images/medal-star.webp';
import flashCircle from 'assets/images/flash-circle.webp';
import bubbleMessage from 'assets/images/bubble-message.webp';
import leaves from 'assets/images/red-bg-leaves.webp';
import leaves1 from 'assets/images/red-bg-leaves-1.webp';

import { ProgramContentCard } from './ProgramContentCard';

export const PainProgramContent = () => {
  const { t } = useTranslation();

  return (
    <section className="index-screen__programcontent">
      <img src={leaves} alt="leaves shape" className="index-screen__programcontent_leaves" />
      <img src={leaves1} alt="leaves shape" className="index-screen__programcontent_leaves1" />
      <div className="index-screen__programcontent_whitewavebg" />
      <div className="index-screen__programcontent_whitewavebg bottom" />
      <div className="index-screen__programcontent_wrapper">
        <h2 className="index-screen__programcontent_title">
          {t('evieOnboarding.indexScreen.programContent.pain.title')}
        </h2>
        <ProgramContentCard>
          <div className="index-screen__programcontentcard_icon">
            <img src={tagUser} alt="tag user icon" />
          </div>
          <p className="index-screen__programcontentcard_text">
            <Trans components={{ strong: <strong /> }}>evieOnboarding.indexScreen.programContent.pain.card1</Trans>
          </p>
        </ProgramContentCard>
        <ProgramContentCard>
          <div className="index-screen__programcontentcard_icon">
            <img src={medalStar} alt="medal star icon" />
          </div>
          <p className="index-screen__programcontentcard_text">
            <Trans components={{ strong: <strong /> }}>evieOnboarding.indexScreen.programContent.pain.card2</Trans>
          </p>
        </ProgramContentCard>
        <ProgramContentCard>
          <div className="index-screen__programcontentcard_icon">
            <img src={flashCircle} alt="flash circle icon" />
          </div>
          <p className="index-screen__programcontentcard_text">
            <Trans components={{ strong: <strong /> }}>evieOnboarding.indexScreen.programContent.pain.card3</Trans>
          </p>
        </ProgramContentCard>
        <ProgramContentCard>
          <div className="index-screen__programcontentcard_icon">
            <img src={bubbleMessage} alt="bubble message icon" />
          </div>
          <p className="index-screen__programcontentcard_text">
            <Trans components={{ strong: <strong /> }}>evieOnboarding.indexScreen.programContent.pain.card4</Trans>
          </p>
        </ProgramContentCard>
      </div>
    </section>
  );
};
