import React from 'react';
import PropTypes from 'prop-types';
import { FaRegFileAlt, FaRegFolder } from 'react-icons/fa';

import { useToggle } from 'utils/hooks/useToggle';
import medalIcon from 'assets/images/medal-icon.svg';

import ProgramContentDescription from '../ProgramContentDescription';
import ProgramContentBenefits from '../ProgramContentBenefits';
import ProgramContentUnits from '../ProgramContentUnits';

const ProgramContentCard = ({ isOpen, content }) => {
  const [displayContent, setDisplayContent] = useToggle(isOpen);

  const icon = {
    description: <FaRegFileAlt />,
    benefits: <img src={medalIcon} alt="medal icon" />,
    content: <FaRegFolder />,
  };

  const title = {
    description: 'What is your program about?',
    benefits: 'Learn about the benefits',
    content: 'Your content',
  };

  const programContent = {
    description: <ProgramContentDescription />,
    benefits: <ProgramContentBenefits />,
    content: <ProgramContentUnits />,
  };

  return (
    <section className="program-content-card-container">
      <section type="button" className={`program-content-card ${content || ''}`}>
        <button type="button" className="program-content-card__title" onClick={setDisplayContent}>
          <div className="program-content-card__title_icon">{icon[content]}</div>
          <h2 className="program-content-card__title_label">{title[content]}</h2>
        </button>
        {displayContent ? programContent[content] : null}
      </section>
    </section>
  );
};

ProgramContentCard.defaultProps = {
  isOpen: false,
};

ProgramContentCard.propTypes = {
  isOpen: PropTypes.bool,
  content: PropTypes.oneOf(['description', 'benefits', 'content']).isRequired,
};

export default ProgramContentCard;
