/* eslint-disable import/order */
import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { DISCOUNTS } from '../utils/constants';
import evieLogo from 'assets/images/index-logo.webp';

import SolidButton from 'components/SolidButton';

export const formatTime = (time) => (time < 10 ? `0${time}` : time);
export const minutesToMillis = (min) => min * 1000 * 60;
export const deadline = 900000;

const HeaderIndex = ({ isDiscount, identifier }) => {
  const { t } = useTranslation();
  const offeringsNode = document.getElementById('offerings');

  const interval = useRef(null);
  const [millis, setMillis] = useState(null);

  const minute = Math.floor(millis / 1000 / 60) % 60;
  const seconds = Math.floor(millis / 1000) % 60;
  const countDown = () => {
    setMillis((prev) => {
      if (prev === 0) {
        clearInterval(interval.current);
        return prev;
      }
      const timeLeft = prev - 1000;
      return timeLeft;
    });
  };

  useEffect(() => {
    setMillis(deadline);
  }, [deadline]);

  useEffect(() => {
    interval.current = setInterval(countDown, 1000);
    return () => clearInterval(interval.current);
  }, []);

  const handleScrollToNode = () => {
    if (offeringsNode) {
      offeringsNode.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <header className={`index-screen__header ${isDiscount ? 'discount' : ''}`}>
      <img src={evieLogo} alt="Evie Logo" className="index-screen__header_logo" />
      <SolidButton className="evie-index pulse-button" onClick={handleScrollToNode}>
        {t('evieOnboarding.indexScreen.header.btn')}
      </SolidButton>
      {isDiscount ? (
        <div className="index-screen__offerbanner">
          <p className="index-screen__offerbanner_percentage">
            {t('evieOnboarding.indexScreen.banner.percentage')} {100 - DISCOUNTS[identifier]}%
          </p>
          <p className="index-screen__offerbanner_deadline">
            {t('evieOnboarding.indexScreen.banner.deadline')}{' '}
            <strong>
              {formatTime(minute)}:{formatTime(seconds)}
            </strong>
          </p>
        </div>
      ) : null}
      {/* <div className={`index-screen__header_blurbg ${isDiscount ? 'discount' : ''}`} /> */}
    </header>
  );
};

HeaderIndex.defaultProps = { isDiscount: false, identifier: null };

HeaderIndex.propTypes = { isDiscount: PropTypes.bool, identifier: PropTypes.string };

export default HeaderIndex;
