import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Loader from 'react-loader-spinner';
import { Formik, Field, Form } from 'formik';
import { isEmpty } from 'ramda';
// import { BiCheck } from 'react-icons/bi';
import ReactTooltip from 'react-tooltip';

import { useRedirectByRole } from 'utils/hooks/useRedirectByRole';
import { useGetIndividuals } from 'utils/hooks/useGetIndividuals';
// import { useToggle } from 'utils/hooks/useToggle';
import { useFormInput } from 'utils/hooks';
import { ROLES } from 'utils/constants';
import { customPlanForm as createNotification } from 'utils/validationSchema';
import { useCreateNotification } from 'components/createNotification/service/useCreateNotification';

import LeftMenu from 'components/LeftMenu';
import Header from 'components/Header';
import SolidButton from 'components/SolidButton';
import SearchFilter from 'components/SearchFilter';
import UsersList from 'components/createNotification/UsersList';
import { NoPatientsFound as NoEmployeesFound } from 'components/EmptyState';
import { TextInput, TextAreaInput } from 'components/FormComponents';

import './create-notification.scss';

const initialValues = {
  title: '',
  description: '',
};

const CreateNotification = () => {
  useRedirectByRole([ROLES.SUPER_CLINIC]);
  const { t } = useTranslation();

  const [selectedUsers, setSelectedUsers] = useState([]);
  // const [selectAll, setSelectAll] = useToggle();

  const searchUsers = useFormInput('');
  const { value = '' } = { ...searchUsers };

  const { onPreviousPage, onNextPage, isLoading, previousPage, nextPage, users } = useGetIndividuals({
    searchValue: value,
  });
  const { spinner, handleCreateNotification } = useCreateNotification(selectedUsers, setSelectedUsers);

  const handleSelectUser = (userId) => {
    setSelectedUsers((prevSelected) => {
      const isSelected = prevSelected.find((item) => item === userId);
      if (isSelected) {
        return prevSelected.filter((item) => item !== userId);
      }
      return [...prevSelected, userId];
    });
  };

  /* const handleSelectAll = () => {
    if (!selectAll) {
      setSelectedUsers((prevSelected) => {
        const remaining = users.map(({ node: { id } }) => id).filter((userId) => !prevSelected.includes(userId));
        return [...prevSelected, ...remaining];
      });
      setSelectAll();
    } else {
      setSelectedUsers([]);
      setSelectAll();
    }
  }; */

  return (
    <section className="d-flex">
      <LeftMenu />
      <div className="dashboard__main">
        <Header title={t('leftMenuHeader.notification')} shadow />
        <div className="create-notification">
          <ReactTooltip place="left" effect="solid" id="select" />
          <div className="create-notification__fields">
            <Formik
              initialValues={initialValues}
              onSubmit={handleCreateNotification}
              validationSchema={createNotification}
              enableReinitialize
            >
              {({ isSubmitting, isValid }) => {
                const isDisabledButton = [!isValid, isSubmitting, isEmpty(selectedUsers)].includes(true);
                return (
                  <Form>
                    <Field type="text" label={t('formLabel.title')} name="title" component={TextInput} />
                    <Field
                      type="text"
                      label={t('formLabel.description')}
                      name="description"
                      minRows={4}
                      component={TextAreaInput}
                    />

                    <SolidButton
                      className={`mt-4 ml-auto ${isDisabledButton ? 'disabled' : ''}`}
                      disabled={isDisabledButton}
                      type="submit"
                    >
                      {t('button.create')}
                    </SolidButton>
                  </Form>
                );
              }}
            </Formik>
          </div>
          <div className="create-notification__list">
            <div className="create-notification__list__filters">
              <SearchFilter searchItems={searchUsers} placeholder={t('searchLabel.individuals')} />
              {/* <div className="create-notification__list__filters__selection-button">
                <span className="select-all-text">{selectAll ? t('tooltip.unselectAll') : t('tooltip.selectAll')}</span>
                <button
                  data-tip={selectAll ? t('tooltip.unselectAll') : t('tooltip.selectAll')}
                  data-for="select"
                  type="button"
                  onClick={handleSelectAll}
                  className={`create-notification__list__filters_select ${
                    selectAll ? 'create-notification__list__filters_select--selected' : ''
                  }`}
                >
                  {selectAll && <BiCheck />}
                </button>
              </div> */}
            </div>
            {!isEmpty(users) && (
              <UsersList users={users} selectedUsers={selectedUsers} handleSelectUser={handleSelectUser} />
            )}
            {isEmpty(users) && <NoEmployeesFound isModal />}
            <div className="create-notification__fetchmorebuttons">
              {previousPage ? (
                <SolidButton onClick={onPreviousPage} className="slim secondary mr-2">
                  back
                </SolidButton>
              ) : null}
              {nextPage ? (
                <SolidButton onClick={onNextPage} className="slim">
                  next
                </SolidButton>
              ) : null}
            </div>
          </div>
        </div>
      </div>
      <Loader
        type="ThreeDots"
        color="#495fd7"
        height={100}
        width={100}
        className="spinner"
        visible={isLoading || spinner}
      />
    </section>
  );
};

export default CreateNotification;
