import React from 'react';

import Layout from 'components/evieOnboarding/Layout';
import BottomNav from 'components/evieApp/BottomNav';

const EvieMyProgram = () => {
  return (
    <Layout>
      <BottomNav />
      <section style={{ height: '100vh', height: '100svh', display: 'grid', placeItems: 'center' }}>My Program</section>
    </Layout>
  );
};

export default EvieMyProgram;
