import React from 'react';

import Layout from 'components/evieOnboarding/Layout';
import BottomNav from 'components/evieApp/BottomNav';

const EvieProfile = () => {
  console.log('RENDERING PROFILE');

  return (
    <Layout>
      <BottomNav />
      <section style={{ height: '100vh', height: '100svh', display: 'grid', placeItems: 'center' }}>Profile</section>
    </Layout>
  );
};

export default EvieProfile;
