import React from 'react';
import PropTypes from 'prop-types';
import { IoMdClose } from 'react-icons/io';

const DailyExercises = ({ changeHomeStatus }) => (
  <section className="daily-exercises">
    <IoMdClose className="daily-exercises__closeicon" onClick={changeHomeStatus} />
    <div>Daily Exercises</div>
  </section>
);

DailyExercises.propTypes = {
  changeHomeStatus: PropTypes.func.isRequired,
};

export default DailyExercises;
