import React from 'react';

import { useToggle } from 'utils/hooks/useToggle';
import fakeImg from 'assets/images/fake-img-unit.png';

import UnitDescriptionModal from '../UnitDescriptionModal';

const UnitCard = () => {
  const [displayUnitDescription, setDisplayUnitDescription] = useToggle();
  return (
    <>
      <button type="button" className="unit-card" onClick={setDisplayUnitDescription}>
        <div className="unit-card__img">
          <img src={fakeImg} alt="woman" />
        </div>
        <div className="unit-card__title">
          <h3>Unit 1</h3>
          <p>Opening of the lower pelvic strait (Week 34-36)</p>
        </div>
      </button>
      {displayUnitDescription && (
        <UnitDescriptionModal modalIsOpen={displayUnitDescription} toggleModal={setDisplayUnitDescription} />
      )}
    </>
  );
};

export default UnitCard;
