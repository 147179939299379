import React from 'react';
import PropTypes from 'prop-types';
import { MdClose } from 'react-icons/md';

import Modal from 'components/Modal';

const modalProps = {
  content: {
    width: '90%',
    maxWidth: 440,
    padding: '20px 16px',
    borderRadius: 12,
  },
};

const UnitDescriptionModal = ({ modalIsOpen, toggleModal }) => (
  <Modal
    closeOnOverlayClick={false}
    closeOnEsc={false}
    modalProps={modalProps}
    modalIsOpen={modalIsOpen}
    closeModal={toggleModal}
    closeable={false}
  >
    <section className="unit-description-modal">
      <MdClose className="unit-description-modal__closeicon" onClick={toggleModal} />
      <h3>Opening of the lower pelvic strait (week 34-36)</h3>
      <p>
        Nuestro objetivo es brindarte las herramientas y el apoyo necesarios para que disfrutes de esta etapa única y
        prepares tu cuerpo y mente para la llegada de tu bebé. Nuestro Programa de Embarazo Saludable está diseñado
        especialmente para acompañarte en cada etapa de tu embarazo, ofreciendo una combinación integral de ejercicios,
        lecturas y audios con recomendaciones prácticas y basadas en evidencia científica.
      </p>
    </section>
  </Modal>
);

UnitDescriptionModal.propTypes = {
  modalIsOpen: PropTypes.bool.isRequired,
  toggleModal: PropTypes.func.isRequired,
};

export default UnitDescriptionModal;
