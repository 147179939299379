import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { GrMicrophone } from 'react-icons/gr';
import { AiOutlineClockCircle } from 'react-icons/ai';

const DailyExercisesCard = ({ changeHomeStatus }) => {
  const { t } = useTranslation();

  return (
    <button type="button" className="daily-ex-card" onClick={changeHomeStatus}>
      <div>
        <h2 className="daily-ex-card__title">{t('evieApp.dailyExerciseCard.title')}</h2>
        <p className="daily-ex-card__content">{t('evieApp.dailyExerciseCard.content')}</p>
      </div>
      <div className="daily-ex-card__labels">
        <p>
          <GrMicrophone /> {t('evieApp.dailyExerciseCard.guideLabel')}
        </p>
        <p>
          <AiOutlineClockCircle /> {t('evieApp.dailyExerciseCard.timeLabel')}
        </p>
      </div>
    </button>
  );
};

DailyExercisesCard.propTypes = {
  changeHomeStatus: PropTypes.func.isRequired,
};

export default DailyExercisesCard;
