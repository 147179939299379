import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { FaRegStar } from 'react-icons/fa';

import { ROUTES } from 'utils/constants';

const GoPlusCard = () => {
  const { t } = useTranslation();
  const { push } = useHistory();

  return (
    <button type="button" className="go-plus-card" onClick={() => push(ROUTES.EVIE_ONBOARDING)}>
      <div className="go-plus-card__staricon">
        <FaRegStar />
      </div>
      <div className="go-plus-card__content">
        <h2>{t('evieApp.goPlusCard.title')}</h2>
        <p>{t('evieApp.goPlusCard.content')}</p>
      </div>
    </button>
  );
};

export default GoPlusCard;
