import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { path, propOr } from 'ramda';
import moment from 'moment';
import { useQuery } from '@apollo/react-hooks';
import Loader from 'react-loader-spinner';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';

import { useAuthQuery } from 'utils/hooks';
import { useRedirectByRole } from 'utils/hooks/useRedirectByRole';
import { ROLES, ROUTES } from 'utils/constants';
import { capitalize } from 'utils/string';
import { GET_EVENTS, GET_BOOKINGS, VALIDATE_USER } from 'apollo/queries';
import { getUserRole } from 'utils/getUserRole';
import { LEFT_MENU } from 'utils/leftMenu';

import Header from 'components/Header';
import Button from 'components/SolidButton';
import MonthView from './MonthView';
import DayView from './DayView';
import WeekView from './WeekView';
import AvailabilityModal from './AvailabilityModal';

import 'moment/locale/es';
import './calendar.scss';

moment.locale = 'es';

const Calendar = (props) => {
  useRedirectByRole([ROLES.CLINIC, ROLES.SUPER_CLINIC, ROLES.SPECIALIST]);
  const { userRole } = getUserRole();
  const { location } = { ...props };
  const { push } = useHistory();
  const { state: fromOnBoarding } = { ...location };
  const { loading: loadingEvents, data: dataEvents, refetch: refetchEvents } = useAuthQuery(GET_EVENTS);
  const { loading: loadingBookings, data: dataBookings, refetch: refetchBookings } = useAuthQuery(GET_BOOKINGS);

  const loading = loadingEvents || loadingBookings;
  const events = propOr([], 'events')(dataEvents);
  const { clinicDashboard: bookings = {} } = { ...dataBookings };
  const { upcommingBookings: calls = [], awaitingResponseBookings: awaitingCalls = [] } = { ...bookings };
  const { data: clinicData } = useQuery(VALIDATE_USER);
  const { getClinicInfo: clinic = {} } = { ...clinicData };
  const { availabilities = [] } = { ...clinic };
  const changeRoute = path(['history', 'push'])(props);
  const [view, setView] = useState('week');
  const [current, setCurrent] = useState(moment());
  const [modal, setModal] = useState(false);

  const toggleModal = () => setModal(!modal);

  useEffect(() => {
    if (fromOnBoarding) toggleModal();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fromOnBoarding]);

  const getEventList = () => [
    ...events.map((event) => ({ ...event, type: 'event' })),
    ...calls.map((call) => {
      const { startAt, patient = {} } = { ...call };
      const { name: title, id: patientId, userType } = { ...patient };
      return {
        title,
        startAt,
        patientId,
        type: 'call',
        userType
      };
    }),
    ...awaitingCalls.map((awaitingCall) => {
      const { startAt, patient = {} } = { ...awaitingCall };
      const { name: title, id: patientId, userType } = { ...patient };
      return {
        title,
        startAt,
        patientId,
        type: 'awaiting-call',
        userType,
      };
    }),
  ];

  useEffect(() => {
    refetchEvents();
    refetchBookings();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const currentLabel = () => {
    if (view === 'day') {
      return capitalize(current.format('MMMM DD, YYYY'));
    }
    return capitalize(current.format('MMMM YYYY'));
  };

  const isCurrentRange = (day) => {
    if (view === 'day') {
      return current.date() === day;
    }
    const startW = moment(current).startOf('week');
    const endW = moment(current).endOf('week');
    return moment(current).startOf('month').date(day).isBetween(startW, endW, undefined, '[]');
  };

  const generateMonthPanel = () => {
    const newDays = [];
    const start = moment(current).startOf('month');
    const startWD = start.weekday();
    const end = moment(current).endOf('month');
    const endWD = end.weekday();
    let firstSelected = null;
    let lastSelected = null;
    if (startWD > 0) {
      for (let i = startWD; i > 0; i--) {
        newDays.push({
          value: '--',
          past: true,
          selected: false,
        });
      }
    }
    for (let j = 1; j <= end.date(); j++) {
      const isRange = view !== 'month' && isCurrentRange(j);
      if (!firstSelected && isRange) {
        firstSelected = j;
        lastSelected = firstSelected + (view === 'day' ? 0 : 6);
      }
      newDays.push({
        value: `${j < 10 ? '0' : ''}${j}`,
        selected: isRange,
        first: firstSelected === j,
        last: lastSelected === j,
      });
    }
    if (endWD < 6) {
      for (let k = 6; k > endWD; k--) {
        newDays.push({
          value: '--',
          past: true,
          selected: false,
        });
      }
    }
    return newDays;
  };

  const isToday = ({ value: day }) => `${moment(current).format('YYYYMM')}${day}` === moment().format('YYYYMMDD');

  const onSelectDay = (date) => {
    setCurrent(moment(date, 'YYYYMMD'));
    setView('day');
  };

  const onSelectMonthPanel = (day) => {
    const el = moment(current, 'YYYYMMD').format('YYYYMM') + day;
    setCurrent(moment(el));
  };

  return (
    <div className="d-flex">
      <AvailabilityModal open={modal} onCloseModal={toggleModal} availabilities={availabilities} />
      {LEFT_MENU[userRole]}
      <div className="dashboard__main">
        <Header title="Calendario" shadow />
        <div className="dashboard__content--patients">
          {loading && (
            <div className="loading-container">
              <Loader type="ThreeDots" color="#495fd7" height={100} width={100} visible={loading} />
            </div>
          )}

          {!loading && (
            <div className="patients__container">
              <div style={{ display: 'flex', width: '100%', height: '100%' }}>
                <div style={{ width: '20%' }}>
                  <div className="month-panel">
                    <div className="month-panel-title">
                      <span>
                        <b>{currentLabel()}</b>
                      </span>
                      <div className="arrow-buttons">
                        <Button
                          className="slim no-min-width secondary"
                          onClick={() => setCurrent(moment(current).subtract(1, view))}
                        >
                          <FaChevronLeft />
                        </Button>
                        <Button
                          className="slim no-min-width secondary"
                          onClick={() => setCurrent(moment(current).add(1, view))}
                        >
                          <FaChevronRight />
                        </Button>
                      </div>
                    </div>
                    <div className="month-panel-body">
                      <span className="month-panel-item">L</span>
                      <span className="month-panel-item">M</span>
                      <span className="month-panel-item">M</span>
                      <span className="month-panel-item">J</span>
                      <span className="month-panel-item">V</span>
                      <span className="month-panel-item">S</span>
                      <span className="month-panel-item">D</span>
                      {generateMonthPanel().map((item, index) => (
                        <span
                          key={index}
                          className={`month-panel-item ${!isNaN(Number(item.value)) && 'clickable'} ${
                            item.past && 'past'
                          } ${item.selected && 'selected'} ${item.first && 'first-child'} ${item.last && 'last-child'}`}
                          onClick={() => !isNaN(Number(item.value)) && onSelectMonthPanel(item.value)}
                        >
                          <div className={`content-item ${isToday(item) && 'today'}`}>{item.value}</div>
                        </span>
                      ))}
                    </div>
                  </div>
                </div>
                <div style={{ flexGrow: 1, marginLeft: '40px' }}>
                  <div className="calendar-top-bar">
                    <Button className="slim extra-width" onClick={() => push(ROUTES.AVAILABILITY)}>
                      Configurar disponibilidad
                    </Button>
                    <div className="view-selector">
                      <Button
                        className={`slim min-width-100 ${view !== 'day' ? 'secondary' : ''}`}
                        onClick={() => setView('day')}
                      >
                        Día
                      </Button>
                      <Button
                        className={`slim min-width-100 ${view !== 'week' ? 'secondary' : ''}`}
                        onClick={() => setView('week')}
                      >
                        Semana
                      </Button>
                      <Button
                        className={`slim min-width-100 ${view !== 'month' ? 'secondary' : ''}`}
                        onClick={() => setView('month')}
                      >
                        Mes
                      </Button>
                    </div>
                    <div className="current-selector">
                      <Button
                        className="slim no-min-width secondary"
                        onClick={() => setCurrent(moment(current).subtract(1, view))}
                      >
                        <FaChevronLeft />
                      </Button>
                      <Button className="slim min-width-100 secondary" onClick={() => setCurrent(moment())}>
                        Hoy
                      </Button>
                      <Button
                        className="slim no-min-width secondary"
                        onClick={() => setCurrent(moment(current).add(1, view))}
                      >
                        <FaChevronRight />
                      </Button>
                    </div>
                  </div>
                  <div className="calendar-body">
                    {view === 'month' && (
                      <MonthView current={current} events={getEventList()} onSelectDay={onSelectDay} />
                    )}
                    {view === 'day' && <DayView current={current} events={getEventList()} changeRoute={changeRoute} />}
                    {view === 'week' && (
                      <WeekView
                        current={current}
                        events={getEventList()}
                        onSelectDay={onSelectDay}
                        changeRoute={changeRoute}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Calendar;
