import React, { useState } from 'react';
import { isEmpty } from 'ramda';
import { BiCheck } from 'react-icons/bi';

import SolidButton from 'components/SolidButton';
import SendMessageModal from 'components/Patients/SendMessageModal';

import { useToggle } from 'utils/hooks/useToggle';

import maleAvatar from 'assets/images/patient-male.svg';
import femaleAvatar from 'assets/images/patient-female.svg';

import './users-list.scss';

const UsersList = (props) => {
  const { users, selectedId, paramsId, onSelectUser, usersView } = { ...props };
  const [modalIsOpen, toggleModal] = useToggle();
  const [selectedUsers, setSelectedUsers] = useState([]);

  const handleSelectUsers = (userId) => {
    setSelectedUsers((prevSelected) => {
      const isSelected = prevSelected.find((item) => item === userId);
      if (isSelected) {
        return prevSelected.filter((item) => item !== userId);
      }
      return [...prevSelected, userId];
    });
  };

  const handleUnselectAll = () => {
    setSelectedUsers([]);
  };

  const getFirstName = (patientName) => (patientName ? patientName.slice(0, patientName.indexOf(' ')) : null);

  const getInitials = (patientName, patientEmail) => (patientName || patientEmail).slice(0, 2).toUpperCase();

  return (
    <>
      {!isEmpty(selectedUsers) && !usersView && (
        <SolidButton onClick={toggleModal} className="slim send-button">
          Enviar mensaje
        </SolidButton>
      )}
      <div className="letter-container" style={{ marginTop: 12 }}>
        {users.map((user) => {
          const { node: curentUser } = user;
          const { name = '', gender = '', cardId = '', email = '', id = '', status = '' } = curentUser;
          const isSelected = (paramsId || selectedId) === id;
          const isSelectedToSendEmail = [...selectedUsers].indexOf(id) !== -1;

          return (
            <div key={`${id}-${cardId}`} className="container-patient">
              {!usersView ? (
                <div
                  aria-hidden="true"
                  className={`letter-container__patient ${isSelected ? 'selected' : ''} ${status}`}
                  onClick={() => onSelectUser(isSelected, { user: curentUser })}
                >
                  <div className="avatar">
                    <img src={gender === 'Hombre' ? maleAvatar : femaleAvatar} alt={name} />
                  </div>
                  <div className="data">
                    <p className={`${isSelected ? 'data__selected' : ''}`}>{name}</p>
                    <p className={`${isSelected ? 'data__selected' : ''}`}>{cardId}</p>
                    <p className={`${isSelected ? 'data__selected' : ''}`}>{email}</p>
                  </div>
                </div>
              ) : (
                <div
                  aria-hidden="true"
                  className={`letter-container__view ${isSelected ? 'selected' : ''} ${status}`}
                  onClick={() => onSelectUser(isSelected, { user: curentUser })}
                >
                  <div className="initials">
                    <p className="initials__init">{getInitials(name, email)}</p>
                  </div>
                  <p className={`name name--${isSelected ? 'selected' : ''}`}>{getFirstName(name)}</p>
                </div>
              )}
              {!usersView && (
                <div
                  aria-hidden="true"
                  onClick={() => handleSelectUsers(id)}
                  className={`container-patient__checkbox container-patient__checkbox--${
                    isSelectedToSendEmail ? 'selected' : ''
                  }`}
                >
                  {isSelectedToSendEmail && <BiCheck />}
                </div>
              )}
            </div>
          );
        })}
      </div>
      <SendMessageModal
        modalIsOpen={modalIsOpen}
        onCloseModal={toggleModal}
        selectedUsers={selectedUsers}
        unselectUsers={handleUnselectAll}
      />
    </>
  );
};

export default UsersList;
