import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { DISCOUNTS } from '../utils/constants';
import { formatPrice } from '../utils/helpers';

const OfferingCard = ({ offer, selectedOffer, setSelectedOffer, isDiscount, identifier: urlIdentifier }) => {
  const { t } = useTranslation();
  const {
    identifier,
    rcBillingProduct: {
      currentPrice: { currency, amount },
    },
  } = offer;

  const isSelected = selectedOffer && selectedOffer.identifier === identifier;
  const isAnnual = offer.identifier === '$rc_annual';
  const title = isAnnual
    ? t('evieOnboarding.planScreen.offering.annual')
    : t('evieOnboarding.planScreen.offering.monthly');
  const discount = DISCOUNTS[urlIdentifier] || 1;

  return (
    <button
      type="button"
      onClick={() => setSelectedOffer(offer)}
      className={`plan-screen__offeringcard ${isSelected ? 'selected' : ''}`}
    >
      {isDiscount && isSelected && (
        <div className="offeringcard-top">
          <p>{t('evieOnboarding.planScreen.offering.trial')}</p>
        </div>
      )}
      <div className="offeringcard-mid">
        <div className={`plan-screen__offeringcard_trial ${!isDiscount && isSelected ? 'selected' : ''}`}>
          <span>{t('evieOnboarding.planScreen.offering.trial')}</span>
        </div>
        <div className="plan-screen__offeringcard_pricing">
          <span className={`radiobutton ${isSelected ? 'selected' : ''}`} />
          <p className="offering-price">
            {formatPrice(amount, currency, isAnnual)}
            <span className="offering-price-label">/mo</span>
          </p>
        </div>
        <div className="plan-screen__offeringcard_description">
          <p className="plan-screen__offeringcard_periodicity">{title}</p>
          {isAnnual ? (
            <p className="plan-screen__offeringcard_periodicity billed">
              {t('evieOnboarding.planScreen.offering.billed.annual')} {formatPrice(amount, currency)}
            </p>
          ) : (
            <p className="plan-screen__offeringcard_periodicity billed">
              {t('evieOnboarding.planScreen.offering.billed.monthly')}
            </p>
          )}
        </div>
      </div>
      <div className={`offeringcard-bottom ${isDiscount && isSelected ? 'selected' : ''}`}>
        {isDiscount ? (
          <p className="offeringcard-bottom_discount">
            Save {formatPrice((amount * (100 - discount)) / discount, currency)}
          </p>
        ) : null}
        <p className="offeringcard-bottom_label">{t('evieOnboarding.planScreen.offering.access')}</p>
      </div>
    </button>
  );
};

OfferingCard.defaultProps = {
  selectedOffer: null,
  isDiscount: false,
  identifier: null,
};

OfferingCard.propTypes = {
  offer: PropTypes.shape({
    identifier: PropTypes.string.isRequired,
    rcBillingProduct: PropTypes.shape({
      currentPrice: PropTypes.shape({
        currency: PropTypes.string.isRequired,
        amount: PropTypes.number.isRequired,
      }).isRequired,
    }).isRequired,
  }).isRequired,
  selectedOffer: PropTypes.shape({
    identifier: PropTypes.string.isRequired,
  }),
  setSelectedOffer: PropTypes.func.isRequired,
  isDiscount: PropTypes.bool,
  identifier: PropTypes.string,
};

export default OfferingCard;
