import { useEffect } from 'react';

import { GET_INDIVIDUALS } from 'apollo/queries';
import { useAuthQuery } from 'utils/hooks';

export const useGetIndividuals = ({ numberOfUsers = 50, searchValue = '' }) => {
  const {
    loading = false,
    data = {},
    refetch = () => {},
    fetchMore = () => {},
  } = useAuthQuery(GET_INDIVIDUALS, { variables: { first: numberOfUsers } });
  const { individuals: { edges: userList = [], pageInfo = {} } = {} } = data;
  const { startCursor = '', endCursor = '', hasPreviousPage = false, hasNextPage = true } = pageInfo;

  useEffect(() => {
    refetch();
  }, []);

  useEffect(() => {
    setTimeout(() => {
      if (searchValue.length >= 3) {
        fetchMore({
          variables: { first: numberOfUsers, search: searchValue },
          updateQuery: (_, { fetchMoreResult }) => fetchMoreResult,
        });
      }
      if (searchValue.length === 0) {
        fetchMore({
          variables: { first: numberOfUsers },
          updateQuery: (_, { fetchMoreResult }) => fetchMoreResult,
        });
      }
    }, 300);
  }, [searchValue]);

  const handlePreviousPage = () => {
    fetchMore({
      variables: { first: null, last: numberOfUsers, before: startCursor },
      updateQuery: (_, { fetchMoreResult }) => fetchMoreResult,
    });
  };

  const handleNextPage = () => {
    fetchMore({
      variables: { first: numberOfUsers, after: endCursor },
      updateQuery: (_, { fetchMoreResult }) => fetchMoreResult,
    });
  };

  return {
    onPreviousPage: handlePreviousPage,
    onNextPage: handleNextPage,
    isLoading: loading,
    previousPage: hasPreviousPage,
    nextPage: hasNextPage,
    users: userList,
  };
};
