import React from 'react';
import { useTranslation, Trans } from 'react-i18next';
import PropTypes from 'prop-types';

import signupLogo from 'assets/images/signup-logo.webp';
import leftLine from 'assets/images/left-line.webp';
import rightLine from 'assets/images/right-line.webp';
import yellowFlame from 'assets/images/yellow-flame.svg';
import blueCloud from 'assets/images/blue-cloud.svg';
import brownPop from 'assets/images/brown-pop.svg';

import SolidButton from 'components/SolidButton';

const TestResultsScreen = ({ goToSignUp }) => {
  const { t } = useTranslation();

  return (
    <section className="test-results-screen">
      <div className="test-results-screen__content">
        <img src={leftLine} alt="design line" className="test-results-screen__content_line" />
        <img src={rightLine} alt="design line" className="test-results-screen__content_line right" />
        <img className="test-results-screen__content_logo" src={signupLogo} alt="Evie logo" />
        <h2 className="test-results-screen__content_title">{t('evieOnboarding.testResultsScreen.title')}</h2>
        <p className="test-results-screen__content_label">{t('evieOnboarding.testResultsScreen.result')}</p>
        <p className="test-results-screen__content_rate">{t('evieOnboarding.testResultsScreen.level')}</p>
        <p className="test-results-screen__content_description">
          <Trans components={{ strong: <strong /> }}>evieOnboarding.testResultsScreen.description</Trans>
        </p>
        <section className="test-results-screen__card">
          <img src={yellowFlame} alt="yellow flame" />
          <div>
            <h3 className="test-results-screen__card_title">
              {t('evieOnboarding.testResultsScreen.symptoms.sexual.label')}
            </h3>
            <p className="test-results-screen__card_content">
              {t('evieOnboarding.testResultsScreen.symptoms.sexual.level')}
            </p>
          </div>
        </section>
        <section className="test-results-screen__card">
          <img src={blueCloud} alt="blue cloud" />
          <div>
            <h3 className="test-results-screen__card_title">
              {t('evieOnboarding.testResultsScreen.symptoms.urinary.label')}
            </h3>
            <p className="test-results-screen__card_content">
              {t('evieOnboarding.testResultsScreen.symptoms.urinary.level')}
            </p>
          </div>
        </section>
        <section className="test-results-screen__card">
          <img src={brownPop} alt="brown pop" />
          <div>
            <h3 className="test-results-screen__card_title">
              {t('evieOnboarding.testResultsScreen.symptoms.bowel.label')}
            </h3>
            <p className="test-results-screen__card_content average">
              {t('evieOnboarding.testResultsScreen.symptoms.bowel.level')}
            </p>
          </div>
        </section>
      </div>
      <div className="test-results-screen__footer">
        <SolidButton className="evie-survey" onClick={goToSignUp}>
          {t('evieOnboarding.signup.submitButton')}
        </SolidButton>
      </div>
    </section>
  );
};

TestResultsScreen.propTypes = {
  goToSignUp: PropTypes.func.isRequired,
};

export default TestResultsScreen;
