/* eslint-disable import/no-unresolved */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay } from 'swiper/modules';
import { FaStar } from 'react-icons/fa';
import 'react-lazy-load-image-component/src/effects/opacity.css';
import 'swiper/css';

import linearGradientBg from 'assets/images/linear-gradient-bg.webp';
import meliAndBaby from 'assets/images/meli-and-baby.webp';
import angie from 'assets/images/angie.webp';
import kathe from 'assets/images/kathe.webp';
import evi from 'assets/images/evi.webp';
import star from 'assets/images/evie-star.svg';

export const PregnancySlides = () => {
  const { t } = useTranslation();

  return (
    <section className="index-screen__testimonials">
      <div className="index-screen__testimonials_title">
        <div className="index-screen__testimonials_titleicon">
          <img src={star} alt="Star" />
        </div>
        <h2 className="index-screen__testimonials_titlelabel">{t('evieOnboarding.planScreen.testimonials.title')}</h2>
      </div>
      <div className="index-screen__testimonials_bg" />
      <Swiper speed={600} slidesPerView={1} autoplay={{ delay: 4000 }} modules={[Autoplay]} loop>
        <SwiperSlide>
          <section className="info-screen__testimonials index-view">
            <div className="info-screen__testimonial index-view">
              <div>
                <FaStar className="info-screen__testimonial_star" />
                <FaStar className="info-screen__testimonial_star" />
                <FaStar className="info-screen__testimonial_star" />
                <FaStar className="info-screen__testimonial_star" />
                <FaStar className="info-screen__testimonial_star" />
              </div>
              <p className="info-screen__testimonial_content">
                {t('evieOnboarding.planScreen.testimonials.pregnancy.meli.content')}
              </p>
              <p className="info-screen__testimonial_author">
                {t('evieOnboarding.planScreen.testimonials.pregnancy.meli.author')}
              </p>
            </div>
            <img className="info-screen__bgimg testimonialbg" src={linearGradientBg} alt="linear gradient background" />
            <img className="info-screen__bgimg info-screen__testimonials_img" src={meliAndBaby} alt="Woman and baby" />
          </section>
        </SwiperSlide>
        <SwiperSlide>
          <section className="info-screen__testimonials index-view">
            <div className="info-screen__testimonial index-view">
              <div>
                <FaStar className="info-screen__testimonial_star" />
                <FaStar className="info-screen__testimonial_star" />
                <FaStar className="info-screen__testimonial_star" />
                <FaStar className="info-screen__testimonial_star" />
                <FaStar className="info-screen__testimonial_star" />
              </div>
              <p className="info-screen__testimonial_content">
                {t('evieOnboarding.planScreen.testimonials.pregnancy.angie.content')}
              </p>
              <p className="info-screen__testimonial_author">
                {t('evieOnboarding.planScreen.testimonials.pregnancy.angie.author')}
              </p>
            </div>
            <img className="info-screen__bgimg testimonialbg" src={linearGradientBg} alt="linear gradient background" />
            <img className="info-screen__bgimg info-screen__testimonials_img" src={angie} alt="Woman and baby" />
          </section>
        </SwiperSlide>
        <SwiperSlide>
          <section className="info-screen__testimonials index-view">
            <div className="info-screen__testimonial index-view">
              <div>
                <FaStar className="info-screen__testimonial_star" />
                <FaStar className="info-screen__testimonial_star" />
                <FaStar className="info-screen__testimonial_star" />
                <FaStar className="info-screen__testimonial_star" />
                <FaStar className="info-screen__testimonial_star" />
              </div>
              <p className="info-screen__testimonial_content">
                {t('evieOnboarding.planScreen.testimonials.pregnancy.kathe.content')}
              </p>
              <p className="info-screen__testimonial_author">
                {t('evieOnboarding.planScreen.testimonials.pregnancy.kathe.author')}
              </p>
            </div>
            <img className="info-screen__bgimg testimonialbg" src={linearGradientBg} alt="linear gradient background" />
            <img className="info-screen__bgimg info-screen__testimonials_img" src={kathe} alt="Woman and baby" />
          </section>
        </SwiperSlide>
        <SwiperSlide>
          <section className="info-screen__testimonials index-view">
            <div className="info-screen__testimonial index-view">
              <div>
                <FaStar className="info-screen__testimonial_star" />
                <FaStar className="info-screen__testimonial_star" />
                <FaStar className="info-screen__testimonial_star" />
                <FaStar className="info-screen__testimonial_star" />
                <FaStar className="info-screen__testimonial_star" />
              </div>
              <p className="info-screen__testimonial_content">
                {t('evieOnboarding.planScreen.testimonials.pregnancy.evi.content')}
              </p>
              <p className="info-screen__testimonial_author">
                {t('evieOnboarding.planScreen.testimonials.pregnancy.evi.author')}
              </p>
            </div>
            <img className="info-screen__bgimg testimonialbg" src={linearGradientBg} alt="linear gradient background" />
            <img className="info-screen__bgimg info-screen__testimonials_img" src={evi} alt="Pregnant woman" />
          </section>
        </SwiperSlide>
      </Swiper>
      <div className="index-screen__testimonials_whitewavebg" />
      <div className="index-screen__testimonials_whitewavebg bottom" />
    </section>
  );
};
