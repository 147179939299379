import React from 'react';

const ProgramContentDescription = () => (
  <section className="program-content">
    <p>
      Nuestro objetivo es brindarte las herramientas y el apoyo necesarios para que disfrutes de esta etapa única y
      prepares tu cuerpo y mente para la llegada de tu bebé. Nuestro Programa de Embarazo Saludable está diseñado
      especialmente para acompañarte en cada etapa de tu embarazo, ofreciendo una combinación integral de ejercicios,
      lecturas y audios con recomendaciones prácticas y basadas en evidencia científica.
    </p>
  </section>
);

export default ProgramContentDescription;
