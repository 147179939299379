import React from 'react';
import { useTranslation } from 'react-i18next';

import SolidButton from 'components/SolidButton';

const NoMembers = ({ title, subtitle, button, link, handleClick, image }) => {
  const { t } = useTranslation();

  return (
    <section className="members-empty-state">
      <div className="content">
        <p className="title">{title}</p>
        {link ? (
          <p className="subtitle">
            {t('employees.empty.invitationLink.share')}{' '}
            <a className="step-by-step" href={link} target="_blank" rel="noopener noreferrer">
              {t('employees.empty.invitationLink.link')}
            </a>{' '}
            {t('employees.empty.invitationLink.join')}
          </p>
        ) : (
          <p className="subtitle">{subtitle}</p>
        )}
        {button && (
          <SolidButton onClick={handleClick} className="mx-2 text-center">
            {button}
          </SolidButton>
        )}
      </div>
      <img src={image} alt="no data" className="image" />
    </section>
  );
};

export default NoMembers;
