import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { GrMicrophone } from 'react-icons/gr';
import { FiVideo, FiBookOpen } from 'react-icons/fi';
import { FaRegPlayCircle } from 'react-icons/fa';

import cardImage from 'assets/images/card-content-img.webp';

const CARD_CONTENT = {
  CAPSULE: 'capsule',
  READING: 'reading',
  AUDIO: 'audio',
};

const ContentCard = ({ content, titleProp, changeHomeStatus }) => {
  const { t } = useTranslation();

  const icon = {
    [CARD_CONTENT.CAPSULE]: <FiVideo />,
    [CARD_CONTENT.READING]: <FiBookOpen />,
    [CARD_CONTENT.AUDIO]: <GrMicrophone />,
  };

  const title = {
    [CARD_CONTENT.CAPSULE]: t('evieApp.contentCard.capsuleLabel'),
    [CARD_CONTENT.READING]: t('evieApp.contentCard.readingLabel'),
    [CARD_CONTENT.AUDIO]: t('evieApp.contentCard.audioLabel'),
  };

  return (
    <button type="button" className="content-card" onClick={changeHomeStatus}>
      {content === CARD_CONTENT.CAPSULE ? <FaRegPlayCircle className="content-card__playicon" /> : null}
      <img src={cardImage} alt="card" className="content-card__img" />
      <div className="content-card__footer">
        <div className="content-card__footertitle">
          <div className="content-card__footericon">{icon[content]}</div>
          <h3 className="content-card__footertitle_label">{title[content]}</h3>
        </div>
        <p className="content-card__footer_description">
          {titleProp
            ? 'short title for capsule'
            : 'footer content with a really long description for various lines in my heart'}
        </p>
      </div>
    </button>
  );
};

ContentCard.defaultProps = {
  titleProp: false,
};

ContentCard.propTypes = {
  content: PropTypes.string.isRequired,
  changeHomeStatus: PropTypes.func.isRequired,
  titleProp: PropTypes.bool,
};

export default ContentCard;
