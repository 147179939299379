import React, { useState } from 'react';
// import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { path, isEmpty } from 'ramda';
import Loader from 'react-loader-spinner';
import { FaUser } from 'react-icons/fa';
import { IoIosArrowForward, IoIosArrowBack } from 'react-icons/io';

import { useFormInput } from 'utils/hooks';
import { useRedirectByRole } from 'utils/hooks/useRedirectByRole';
import { useGetIndividuals } from 'utils/hooks/useGetIndividuals';
import { useToggle } from 'utils/hooks/useToggle';
import { queryParams } from 'utils/string';
import { ROLES, ROUTES } from 'utils/constants';
import { getUserRole } from 'utils/getUserRole';
import { LEFT_MENU } from 'utils/leftMenu';

import Header from 'components/Header';
import UsersList from 'components/Patients/UsersList';
import Patient from 'components/Patients/Patient';
import UnselectedPatient from 'components/Patients/UnselectedPatient';
import { NoPatientsFound } from 'components/EmptyState';
import SolidButton from 'components/SolidButton';
import SearchFilter from 'components/SearchFilter';

import './individuals.scss';

const Individuals = (props) => {
  useRedirectByRole([ROLES.SUPER_CLINIC, ROLES.SPECIALIST]);
  const { userRole } = getUserRole();
  // const { t } = useTranslation();
  const searchParams = path(['history', 'location', 'search'])(props);
  const params = { ...queryParams(searchParams) };
  const { tab: targetTab = '0' } = { ...params };
  const { id } = useParams();
  const changeRoute = path(['history', 'push'])(props);

  const [usersView, setUsersView] = useToggle();
  const [selected, setSelected] = useState({});
  const { user: selectedUser = {} } = selected;
  const { id: selectedId = '' } = selectedUser;
  const searchUsers = useFormInput('');
  const { value = '' } = searchUsers;

  const { onPreviousPage, onNextPage, isLoading, previousPage, nextPage, users } = useGetIndividuals({
    searchValue: value,
  });

  const onSelectUser = (isSelected, { user }) => {
    const { id: selectedUserId } = { ...user };
    if (isSelected) {
      changeRoute(ROUTES.INDIVIDUALS);
      setSelected({});
    } else {
      changeRoute(`${ROUTES.INDIVIDUALS}/${selectedUserId}`);
      setSelected({ user });
    }
  };

  return (
    <div className="d-flex">
      {LEFT_MENU[userRole]}
      <div className="dashboard__main">
        <Header title="Individuals" shadow />
        {isLoading && (
          <div className="loading-container">
            <Loader type="ThreeDots" color="#495fd7" height={100} width={100} visible={isLoading} />
          </div>
        )}
        <div className="patients-view">
          <div className={`patients-container patients-container--${usersView ? 'collapsed' : ''}`}>
            <button type="button" className="arrow-container" onClick={setUsersView}>
              {usersView ? <IoIosArrowForward /> : <IoIosArrowBack />}
            </button>
            <div className={`current-status current-status--${usersView ? 'collapsed' : ''}`}>
              <div className="current-status__text">
                <div className="current-status__text_user">
                  <FaUser />
                </div>
                {!usersView && <p className="added">Usuarios agregados</p>}
              </div>
            </div>
            <SearchFilter
              onClick={usersView ? setUsersView : () => {}}
              searchItems={searchUsers}
              placeholder="Buscar un usuario"
              patientsView={usersView}
              collapsed={usersView ? 'collapsed' : ''}
              widthModifier="individuals"
            />
            <div className={`patients-list patients-list--${usersView ? 'collapsed' : ''}`}>
              <UsersList
                users={users}
                selectedId={selectedId}
                paramsId={id}
                onSelectUser={onSelectUser}
                usersView={usersView}
              />
              {!isLoading && !usersView && isEmpty(users) && <NoPatientsFound />}
            </div>
            <div className="user-pagination-buttons">
              {previousPage ? (
                <SolidButton onClick={onPreviousPage} className="slim secondary mr-2">
                  back
                </SolidButton>
              ) : null}
              {nextPage ? (
                <SolidButton onClick={onNextPage} className="slim">
                  next
                </SolidButton>
              ) : null}
            </div>
          </div>
          <div className={`selected-patient selected-patient--${usersView ? 'collapsed' : ''}`}>
            {!!selectedId || !!id ? (
              <Patient
                targetTab={targetTab}
                changeRoute={changeRoute}
                selectedId={selectedId}
                paramsId={id}
                onUpdateCurrentActive={() => {}}
                hasCalls
                isIndividual
              />
            ) : (
              <UnselectedPatient />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Individuals;
