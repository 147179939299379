import React, { createContext, useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { KEYS } from 'components/evieOnboarding/utils/constants';
import { useLocalStorage } from 'utils/hooks/useLocalStorage';
import { useGetParams } from 'components/evieOnboarding/utils/useGetParams';
import { REACT_APP_IPINFO_API_KEY } from 'config';

const EvieOnboardingContext = createContext();

const EvieOnboardingProvider = ({ children }) => {
  const { urlParams, step } = useGetParams();

  const [params, setParams] = useLocalStorage(KEYS.URL_PARAMS, urlParams);
  const [country, setCountry] = useState(null);

  useEffect(() => {
    if (!params && step === 0 && !urlParams.includes('plan_step')) {
      setParams(urlParams);
    }
  }, []);

  useEffect(() => {
    fetch(`https://ipinfo.io/json?token=${REACT_APP_IPINFO_API_KEY}`)
      .then((response) => response.json())
      .then((data) => setCountry(data.country))
      .catch((error) => console.error('Error fetching location:', error));
  }, []);

  return (
    <EvieOnboardingContext.Provider value={{ params, setParams, country }}>{children}</EvieOnboardingContext.Provider>
  );
};

EvieOnboardingProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

function useEvieOnboardingContext() {
  const context = useContext(EvieOnboardingContext);
  if (!context) {
    throw new Error('Expected an EvieOnboardingContext somewhere in the react tree to set context value');
  }
  return context;
}

export { useEvieOnboardingContext, EvieOnboardingProvider };
