import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { ROUTES } from 'utils/constants';
import { HOME_STATUS } from '../utils';

import ProgramInfoCard from '../ProgramInfoCard';
import SeeMoreButton from '../SeeMoreButton';
import DailyExercisesCard from '../DailyExercisesCard';
import GoPlusCard from '../GoPlusCard';
import WeekContent from '../WeekContent';

const HomeContent = ({ changeHomeStatus }) => {
  const { t } = useTranslation();
  const { push } = useHistory();

  return (
    <section className="evie-home">
      <ProgramInfoCard changeHomeStatus={() => changeHomeStatus(HOME_STATUS.PROGRAM)} />
      <SeeMoreButton content={t('evieApp.seeMore.exLabel')} action={() => push(ROUTES.EVIE_MY_PROGRAM)} />
      <DailyExercisesCard changeHomeStatus={() => changeHomeStatus(HOME_STATUS.EXERCISES)} />
      <GoPlusCard />
      <h2 className="evie-home__programtitle">{t('evieApp.programTitle')}</h2>
      <SeeMoreButton content={t('evieApp.seeMore.weekLabel')} action={() => push(ROUTES.EVIE_MY_PROGRAM)} />
      <WeekContent changeHomeStatus={() => changeHomeStatus(HOME_STATUS.TASK)} />
    </section>
  );
};

HomeContent.propTypes = {
  changeHomeStatus: PropTypes.func.isRequired,
};

export default HomeContent;
