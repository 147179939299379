import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { IoIosArrowForward } from 'react-icons/io';

import smilyWoman from 'assets/images/smily-woman.webp';

const ProgramInfoCard = ({ changeHomeStatus }) => {
  const { t } = useTranslation();

  return (
    <button type="button" className="program-info-card" onClick={changeHomeStatus}>
      <img src={smilyWoman} alt="Smily woman" className="program-info-card__img" />
      <section className="program-info-card__content">
        <div className="program-info-card__content_labels">
          <h2>{t('evieApp.programInfoCard.title')}</h2>
          <p>{t('evieApp.programInfoCard.content')}</p>
        </div>
        <div className="program-info-card__content_arrowicon">
          <IoIosArrowForward />
        </div>
      </section>
    </button>
  );
};

ProgramInfoCard.propTypes = {
  changeHomeStatus: PropTypes.func.isRequired,
};

export default ProgramInfoCard;
