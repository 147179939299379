export const translationEs = {
  leftMenuHeader: {
    newPatient: 'Nuevo Paciente',
    onboarding: 'Inducción',
    home: 'Inicio',
    patients: 'Pacientes',
    plans: 'Planes',
    forms: 'Formularios',
    library: 'Biblioteca',
    calendar: 'Calendario',
    availability: 'Disponibilidad',
    reports: 'Reportes',
    covenants: 'Convenios',
    events: 'Eventos',
    templates: 'Plantillas',
    template: 'Crear nueva Plantilla',
    editTemplate: 'Detalle de la Plantilla',
    readings: 'Lecturas',
    reading: 'Crear nueva Lectura',
    editReading: 'Detalle de la Lectura',
    settings: 'Configuración',
    profile: 'Editar perfil',
    program: 'Crear nuevo Programa',
    editProgram: 'Detalle del programa',
    programs: 'Programas',
    company: 'Crear nueva Empresa',
    editCompany: 'Detalle de la Empresa',
    companiesList: 'Empresas',
    notification: 'Crear Notificación',
    chat: 'Chat',
    activePauses: 'Pausas Activas',
    createActivePause: 'Crear Pausa Activa',
    editActivePause: 'Actualizar Pausa Activa',
    audioList: 'Audios',
    audioDetail: 'Detalle del Audio',
    createAudio: 'Crear nuevo Audio',
    shortVideos: 'Cápsulas de video',
    shortVideoDetail: 'Detalle de la cápsula de video',
    createShortVideo: 'Crear nueva cápsula de video',
    eviePrograms: 'Programas de Evie',
    evieProgramDetail: 'Detalle del programa de Evie',
    createEvieProgram: 'Crear nuevo programa para Evie',
    squeezes: 'Squeezes',
    squeeze: 'Detalle del squeeze',
    createSqueeze: 'Crear nuevo squeeze',
    squeezeDetail: 'Detalle del squeeze',
    individuals: 'Individuals',
    assets: 'Archivos de multimedia',
    companies: {
      invite: 'Invitar empleado',
      employees: 'Empleados',
      rewards: 'Recompensas',
      orders: 'Pedidos',
      invoices: 'Facturas',
      teamCount: {
        actual: 'Tu equipo actual es de',
        of: 'de',
        button: 'Actualiza tu plan',
      },
    },
  },
  navigation: {
    profile: 'Mi Perfil',
    subscriptions: 'Mis Subscripciones',
    terms: 'Términos & Condiciones',
    policy: 'Políticas de Privacidad',
    logout: 'Salir',
  },
  settings: {
    payment: {
      title: 'Programa de Beneficios',
      description: 'Configure la información de pago para los beneficios de sus empleados',
      allowance: 'Cobertura',
      cadence: 'Periodicidad',
      type: 'Tipo de Pago',
      reimbursement: 'Reembolso',
      method: 'Medio de Pago',
      creditCard: 'Tarjeta de Crédito',
      ondemand: 'Por Demanda',
      subscription: 'Subscripción',
      bank: 'Cuenta Bancaria',
      changeType: '* Para cambiar el tipo de pago debe contactar al administrador',
      yearly: 'Anual',
      bi_yearly: 'Semestral',
      quarterly: 'Trimestral',
    },
    profile: {
      title: 'Perfil',
      companyDescription:
        'En está sección podrás editar la información personal de tu perfil, agregando los datos básicos y logo que tus empleados verán en la app mobile.',
      clinicDescription:
        'En está sección podrás editar la información personal de tu perfil, agregando los datos básicos y logo que tus pacientes verán en la app mobile.',
    },
    language: {
      label: 'Configura tu idioma:',
      title: 'Idioma',
      description: 'Configura tu idioma por defecto para la plataforma.',
      placeholder: 'Seleccione un idioma',
    },
    team: {
      title: 'Equipo de trabajo',
      description:
        'Aquí puedes añadir miembros de RRHH para que reciban notificaciones administrativas de la plataforma.',
      addBtn: 'Agregar profesional',
      addModal: {
        addTitle: 'Agregar miembro al equipo',
        updTitle: 'Actualizar miembro del equipo',
      },
      emptyState: {
        title: '¡Aún no tienes ningún profesional en tu equipo!',
      },
    },
  },
  button: {
    delete: 'Eliminar',
    cancel: 'Cancelar',
    exit: 'Salir',
    create: 'Crear',
    update: 'Actualizar',
    remove: 'Quitar',
    add: 'Añadir',
    back: 'Atrás',
    buy: 'Comprar',
    next: 'Siguiente',
    edit: 'Editar',
    invite: 'Invitar',
    change: 'Cambiar',
    save: 'Guardar',
    send: 'Enviar',
    signin: 'Iniciar Sesión',
    signout: 'Salir',
    retrieve: 'Recuperar',
    clinics: 'Clínicas',
    companies: 'Empresas',
    apply: 'Aplicar',
    setup: 'Configurar',
    continue: 'Continuar',
    duplicate: 'Duplicar',
    addImage: 'Agregar imagen',
    categories: 'Categorías',
    upload: 'Cargar',
    plan: 'Ver mi plan',
    assign: 'Asignar programa',
  },
  nofound: {
    title: 'No se han encontrado resultados',
    description:
      'Asegúrate de que el criterio esté escrito correctamente o intenta ajustar el filtro para encontrar lo que estas buscando.',
  },
  searchLabel: {
    employees: 'Buscar un empleado',
    individuals: 'Buscar un individuo',
    rewards: 'Buscar un producto',
    orders: 'Buscar una orden',
    program: 'Buscar un programa',
    companies: 'Buscar una empresa',
    activePauses: 'Buscar una pausa activa',
    invoices: 'Buscar factura',
    squeeze: 'Buscar squeeze',
    asset: 'Buscar imagen',
  },
  filterLabel: {
    companies: 'Filtrar por empresa',
  },
  tabs: {
    all: 'Todos',
    active: 'Activos',
    inactive: 'Inactivos',
    shareLink: 'Compartir link',
    specialInvitation: 'Invitación especial',
    unfulfilled: 'Incompletos',
    fulfilled: 'Completos',
  },
  formLabel: {
    title: 'Título',
    description: 'Descripción',
    name: 'Nombre',
    lastName: 'Apellido',
    email: 'Correo electrónico',
    workEmail: 'Correo electrónico laboral',
    contactNumber: 'Número de contacto',
    address: 'Dirección',
    promoCode: 'Código promocional',
    image: 'Imagen',
    status: 'Estado',
    price: 'Precio en puntos',
    quantity: 'Cantidad',
    country: 'País',
    password: 'Contraseña',
    confirmPassword: 'Confirmar contraseña',
    id: 'Identificación',
    nit: 'NIT',
    phone: 'Teléfono',
    city: 'Ciudad',
    contact: 'Persona de contacto',
    specialist: 'Número de especialistas',
    employee: 'Número de empleados',
    doctorName: 'Nombre del Doctor',
    duration: 'Duración',
    plan: 'Plan',
    backgroundImage: 'Imagen de fondo',
    thumbnailImage: 'Imagen en miniatura',
    posterImage: 'Imagen del póster',
    doctorImage: 'Imagen del Doctor',
    survey: 'Encuesta',
    from: 'Desde',
    to: 'Hasta',
    bodyParts: 'Partes del cuerpo relacionadas',
    color: 'Color',
    month: 'Mes',
    year: 'Año',
    esHeadingLabel: 'Contenido del título en Español',
    enHeadingLabel: 'Contenido del título en Inglés',
    esTextLabel: 'Contenido del bloque de texto en Español',
    enTextLabel: 'Contenido del bloque de texto en Inglés',
    imageUrlLabel: 'Url de la imagen o gif',
    esOptionsLabel: 'Opciones en Español',
    enOptionsLabel: 'Opciones en Inglés',
    bold: 'Negrita',
    type: 'Tipo',
    goal: 'Objetivo',
    laps: 'Vueltas',
    rest: 'Descanso (segundos)',
    date: 'Fecha',
    hour: 'Hora',
    timeZone: 'Zona horaria',
    message: 'Mensaje',
    actions: 'Acciones',
    action: 'Acción',
    file: {
      text: 'Arrastre y suelte un archivo aquí, o haga clic para seleccionar',
      button: 'Añadir archivo',
    },
  },
  placeholder: {
    country: 'Seleccione el país',
    plan: 'Seleccione un plan',
    template: 'Seleccione una plantilla',
    day: 'Seleccione un día',
    survey: 'Seleccione una encuesta',
    searchExercise: 'Nombre del ejercicio',
    audio: 'Buscar un audio',
    shortVideo: 'Buscar una cápsula de video',
  },
  tableHeader: {
    title: 'Título',
    image: 'Imagen',
    doctor: 'Doctor',
    duration: 'Duración',
    name: 'Nombre',
    employees: 'Número de empleados',
    content: 'Contenido',
  },
  deleteForm: {
    title: 'Esta acción no es reversible, ¿estás seguro?',
    description: 'Este formulario será eliminado, incluyendo todas sus configuraciones y preguntas.',
  },
  leaveCreatePatient: {
    description: 'No podrás volver a editar esta información una vez des en ´Salir´',
  },
  notification: {
    deleteForm: 'Se ha eliminado el formulario exitosamente.',
    order: 'Se ha completado la orden exitosamente',
    updateProfile: 'Se ha actualizado el perfil exitosamente.',
    updatePassword: 'Se ha actualizado la contraseña exitosamente.',
    passwordRecovery: 'En tu correo electrónico encontrarás el enlace para el restablecimiento de tu contraseña.',
    passwordRecovered: 'Has recuperado tu contraseña exitosamente.',
    push: 'La notificación PUSH se ha creado exitosamente.',
    coupon: 'Cupón aplicado exitosamente.',
    updateEmployeeStatus: 'Se ha actualizado el empleado exitosamente.',
    program: {
      create: 'Se ha creado el programa exitosamente',
      update: 'Se ha actualizado el programa exitosamente',
    },
    company: {
      create: 'Se ha creado la empresa exitosamente',
      update: 'Se ha actualizado la empresa exitosamente',
    },
    warning: {
      availability: 'Ya existe un espacio en esa franja de tiempo',
      followupModal:
        'Asegúrate de guardar la información antes de continuar, de lo contrario da click en cancelar nuevamente.',
      selectedItem: 'El elemento ya ha sido seleccionado',
    },
    success: {
      availability: 'Se ha guardado la disponibilidad exitosamente.',
      booking: 'Se ha agendado la llamada exitósamente',
    },
    activePause: {
      create: 'Se ha creado la pausa activa exitósamente',
      update: 'Se ha actualizado la pausa activa exitósamente',
    },
    createReading: {
      success: {
        create: 'Se ha creado la lectura exitosamente.',
        update: 'Se ha actualizado la lectura exitosamente.',
      },
    },
  },
  changePassword: {
    title: 'Cambiar contraseña',
    show: 'Mostrar contraseña',
    label: {
      new: 'Nueva contraseña',
      confirm: 'Congirmar nueva contraseña',
    },
    validation: {
      match: 'Las contraseñas deben coincidir',
      uppercase: 'Al menos una letra mayúscula (A-Z)',
      lowercase: 'Al menos una letra minúscula (a-z)',
      number: 'Al menos un número (0-9)',
      character: 'Al menos un carácter especial',
      length: 'Al menos 8 caracteres',
    },
  },
  createEmployee: {
    success:
      'Hemos enviado toda la información para crear un usuario de HealthPhy para empresas en el correo que nos proveíste. Cualquier duda nos puedes contactar a info@healthphy.de',
  },
  editProfile: {
    title: 'Mi perfil',
  },
  createTemplate: {
    readings: {
      title: {
        all: 'Todas las Lecturas',
        own: 'Lecturas en esta Plantilla',
      },
    },
  },
  surveys: {
    welcome: {
      message:
        'Hola, te han enviado unas preguntas desde la plataforma HealthPhy, a continuación encontrarás opciones para llenar con la mayor claridad posible. Esta información es confidencial, así que cuando estés listo dale click en el botón de abajo.',
      button: 'Hacer questionario',
    },
    survey: {
      section: 'Sección {{current}} de {{length}}',
      fileUpload: 'Sube un archivo',
    },
    completed: {
      thanksInit: 'Ya puedes iniciar tus ejercicios personalizados',
      thanks: 'Muchas gracias por tus respuestas',
      data: 'Estos son tus datos para ingresar',
      user: 'Usuario:',
      password: 'Contraseña',
      success: 'La encuesta se ha completado satisfactoriamente y las respuestas serán analizadas por tu profesional.',
      reminder: 'Recuerda cambiar la clave una vez ingreses a la app para validar tu entrada en la plataforma.',
      download: 'Descargarlo ahora',
    },
  },
  billing: {
    clinicTitle: 'Tu encárgate de prestar tu mejor servicio. Nosotros cuidaremos a tus pacientes.',
    clinicDescription:
      'En HealthPhy pagas por el número de pacientes que tienes activos, haciendo mucho más fácil armar el plan a tu medida, con diferentes opciones de pago, según tus necesidades, sin contratos a largo plazo ni licencias complejas. Puedes cancelar tu suscripción en cualquier momento sin costos adicionales ni cuotas de cancelación.',
    companyTitle: 'Tu encárgate de prestar tu mejor servicio. Nosotros cuidaremos a tu equipo de trabajo.',
    companyDescription:
      'En HealthPhy pagas por el número de empleados que tienes activos, haciendo mucho más fácil armar el plan a tu medida, con diferentes opciones de pago, según tus necesidades, sin contratos a largo plazo ni licencias complejas. Puedes cancelar tu suscripción en cualquier momento sin costos adicionales ni cuotas de cancelación.',
    clinicChoose: 'Escoge el número de pacientes:',
    companyChoose: 'Escoge el número de empleados activos en tu empresa:',
    clinicPerks: {
      library: 'Biblioteca con cientos de ejercicios terapéuticos para crear el programa perfecto de rehabilitación.',
      plans: 'Planes de rehabilitación preconfigurados por nuestros especialistas',
      custom: 'Editor de planes personalizados de tratamiento según la necesidad de cada paciente.',
      remote: 'Atención remota por medio de Videollamadas encriptadas en la plataforma para mayor seguridad.',
      statistics: 'Estadísticas y monitoreo del proceso de recuperación.',
      tailor:
        '* Nos adaptamos a tu necesidad, si los videos de la plataforma no se adaptan a ti, ofrecemos grabación de vídeos a medida cuando adquieres un plan igual o superior a 6 meses.',
    },
    companyPerks: {
      surveys:
        'Encuestas para identificación y evaluación de factores de riesgo de trastornos musculoesqueléticos, vasculares y psicosociales.',
      plans: 'Planes de ejercicios preventivos y plan de pausas activas personalizadas.',
      programs:
        'Programas para el manejo y educación en enfermedades crónicas comunes, (hipertensión, diabetes, obesidad, EPOC y/o lesiones musculoesqueléticas).',
      appointments: 'Acceso a la posibilidad de agendar citas con Fisioterapeutas o Psicólogos en menos de 48 horas.',
      support: 'Acompañamiento para la implementación de bienestar digital.',
      statistics:
        'Informe inicial con estadísticas de diagnóstico de las enfermedades para la toma de decisiones y reporte de uso en la plataforma administrativa.',
    },
    planCard: {
      subscription: 'Pago',
      name: {
        yearly: 'Anual',
      },
      discount: 'Descuento',
      save: 'Ahorras',
    },
    users: {
      patient: 'Paciente',
      employee: 'Empleado',
      patients: 'Pacientes',
      employees: 'Empleados',
    },
    timePeriod: {
      monthly: '(mensual)',
    },
    stepIndicator: {
      method: 'Medio de pago',
      detail: 'Detalles del pago',
      confirm: 'Confirmar pago',
    },
    paymentMethod: {
      useCards: 'Usa tus tarjetas de crédito',
      choose: 'Escoge un método de pago',
    },
    summary: {
      title: 'Detalles del pago',
      selectedPlan: 'Plan escogido',
      subtotal: 'Subtotal',
      discount: 'Descuento',
      total: 'Total',
      coupon: 'Cupón',
    },
    paymentData: {
      billingInfo: 'Información de facturación',
      cardInfo: 'Información de tarjeta',
      terms:
        'Entiendo y acepto el <contract>contrato de licencia de usuario final</contract> y la <privacy>política de privacidad</privacy> para hacer esta compra.',
    },
    paymentSummary: {
      title: 'Confirma tu información',
      standard: 'Plan standard de subscripción',
      creditCard: 'Tarjeta de crédito',
      button: 'Confirmar y pagar',
      totalPayment: 'Total del pago',
      total: 'Total',
    },
    success: {
      title: 'Transacción exitosa',
      message:
        '¡Genial!, el pago de tu plan ha sido procesado exitosamente. En breve recibirás a través de tu e-mail la información de esta transacción. En caso de alguna inquietud no dude en escribirnos a',
      button: 'Volver al inicio',
      expires: 'Expira en',
    },
  },
  employees: {
    empty: {
      emptyTitle: '¡Aún no tienes empleados registrados en tu equipo!',
      invitationLink: {
        share: 'Comparte el',
        link: 'link de invitación',
        join: 'para que empiecen a unirse.',
      },
    },
    tableHeader: {
      name: 'Nombre',
      status: 'Estado',
      email: 'Correo electrónico',
      activity: 'Actividad',
      changeStatus: 'Activar/Desactivar',
    },
    invite: {
      title: 'Opciones para añadir un empleado',
      description:
        'Copia este link y compartelo con tu equipo por email, intranet, slack etc. cada miembro del equipo con acceso al link obtendrá una cuenta de Healthphy para empezar a hacer sus sesiones de trabajo más productivas.',
      button: 'Copiar link',
    },
    employeeSpend: {
      header: {
        employeLabel: 'Saldo disponible en beneficios',
        companyLabel: 'Cobertura de beneficios',
      },
      emptyInvoiceItems: {
        label: 'aún no tiene productos gastados',
      },
    },
  },
  rewards: {
    available: 'disponibles',
    empty: {
      title: '!Aún no tienes premios agregados para tus empleados!',
      description: 'Comienza creando productos para que tus empleados los puedan reclamar como premios',
    },
    tableHeader: {
      name: 'Nombre',
      status: 'Estado',
      stock: 'Inventario',
      detail: 'Detalle/Editar',
    },
    productDetail: {
      title: 'Detalle del producto',
      name: 'Nombre',
      description: 'Descripción',
      image: 'Imagen',
      status: 'Estado',
      price: 'Precio en puntos',
      quantity: 'Cantidad',
    },
    addProduct: {
      addTitle: 'Añade un nuevo producto',
      updateTitle: 'Actualiza el producto',
      placeholderStatus: 'Seleccione el estado para el producto',
      status: {
        active: 'Activo',
        inactive: 'Inactivo',
      },
    },
    button: {
      add: 'Añadir producto',
      update: 'Actualizar producto',
    },
  },
  orders: {
    empty: {
      title: 'Aún no tienes pedidos por parte de tus empleados!',
      description: 'Una vez tus empleados rediman productos, aquí podrás ver la lista de pedidos',
      button: 'Ir a empleados',
    },
    tableHeader: {
      name: 'Nombre',
      status: 'Estado',
      employee: 'Empleado',
      createdAt: 'Fecha',
      detail: 'Detalle/Editar',
    },
    orderDetail: {
      product: 'Producto',
      quantity: 'Cantidad',
      points: 'Puntos',
      employee: 'Empleado',
      button: 'Completar',
    },
  },
  howToWin: {
    howto: '¿Cómo ganar puntos?',
    points:
      'Los puntos son motivación extra para mantenerte saludable. Puedes obtenerlos realizando tus sesiones de ejercicios diarios o activando los diferentes progamas dentro de la app.',
    whatcan: '¿En qué los puedo usar?',
    exchange:
      'Puedes cambiar tus puntos por muchos productos/artículos geniales, los encontrarás en la sección de "Redimir puntos" en la parte superior de la pantalla.',
  },
  redeemProduct: {
    title: 'Redimir producto',
    table: {
      product: 'Producto',
      quantity: 'Cnt.',
      points: 'Puntos',
    },
    resume: {
      title: 'Resumen',
      currentPoints: 'Puntos actuales',
      total: 'Total',
      remainingPoints: 'Puntos restantes',
    },
    button: 'Redimir',
    loading: 'Cargando',
    authError: 'No tienes autorización para aceder a está página',
    pointsError: 'No tienes los puntos suficientes para este item, necesitas ',
    more: 'mas',
    successRedeem: 'Su producto ha sido redimido, ¡disfurtalo!',
    errorRedeem: 'Lo sentimos, hubo un error con su petición',
    quantityError: 'Lo sentimos, se nos acabo este producto',
  },
  createProgram: {
    step: {
      detail: 'Detalle del Programa',
      readingTemplate: 'Plantilla de Lecturas',
      audio: 'Añade un Audio',
    },
  },
  programs: {
    button: 'Crear Programa',
    duration: 'días',
  },
  companies: {
    createButton: 'Crear Empresa',
  },
  trial: {
    ended: {
      title: '¡Tu período de prueba a finalizado!',
      message:
        'Gracias por usar y probar nuestro demo, esperamos que hayas disfrutado la versión gratuita. Para continuar disfrutando de los servicios de nuestra plataforma, actualiza tu subscripción escogiendo cualquiera de nuestros planes.',
      button: 'Ver planes',
    },
    period:
      'Esta cuenta está en periodo de prueba y le faltan <span>{{trialDays}} días</span> para culminar, accede a uno de nuestros planes para mejorar tu cuenta',
  },
  login: {
    welcome: 'Bienvenido a HealthPhy',
    forgot: '¿Olvidaste tu contraseña?',
    recover: 'Recuperar contraseña',
    recoverMessage: 'Ingresa tu correo electrónico y te enviaremos un enlace para que recuperes el acceso a tu cuenta.',
    access: 'Recupera el acceso a tu cuenta',
    empty: 'Este link es inválido o a expirado.',
  },
  tooltip: {
    selectAll: 'Seleccionar todos',
    unselectAll: 'Anular selección',
  },
  forms: {
    previewTitle: 'Así es como tu paciente verá el formulario',
  },
  introModal: {
    title: 'Bienvenido a Healthphy, configuremos tu cuenta',
    description:
      'Te damos la bienvenida a HealthPhy, para usar la plataforma es necesario configurar tu información de perfil e inicio de sesión, en las siguientes pantallas te ayudaremos a hacerlo.',
  },
  changeStatusAlert: {
    title: 'Cambiar Estado',
    description: 'Esta acción cambiará el estado de <strong>{{name}}</strong>',
  },
  modalAlert: {
    updateEmployerPlan: {
      title: 'Actualizar Plan',
      message: 'Has alcanzado el número permitido de empleados para tu plan.',
    },
  },
  availability: {
    title: 'Editar disponibilidad horaria',
    description:
      'Configura los bloques de horario por día que tienes disponibles para atender a tus pacientes en llamadas o atender eventos.',
    weekDays: {
      monday: 'Lunes',
      tuesday: 'Martes',
      wednesday: 'Miercoles',
      thursday: 'Jueves',
      friday: 'Viernes',
      saturday: 'Sábado',
      sunday: 'Domingo',
    },
  },
  deletePlan: {
    title: 'Esta acción no es reversible, ¿estás seguro?',
    description: 'Este plan será eliminado, incluyendo todo su contenido y configuraciones',
  },
  activePauses: {
    createButton: 'Crear Pausa Activa',
    emptyState: {
      title: '¡Aún no tienes pausas activas configuradas!',
      subtitle: 'Inicia configurando tu primer pausa activa para la valoración de tus empleados.',
    },
  },
  createActivePause: {
    exercisesTitle: 'Buscar un ejercicio',
    selectedExercisesTitle: 'Vídeos',
  },
  emptyState: {
    description:
      'Asegúrate de que el criterio esté escrito correctamente o intenta ajustar el filtro para encontrar lo que estás buscando.',
  },
  audioList: {
    createButton: 'Crear Audio',
    emptyState: {
      title: '¡Aún no tienes audios configurados!',
      description: 'Inicia configurando un audio para complementar el programa de tu paciente.',
    },
    tableHeader: {
      title: 'Título',
      description: 'Descripción',
    },
  },
  invoices: {
    header: {
      amount: 'Total a pagar del mes',
      dueDate: 'Fecha límite',
      items: 'Artículos',
      status: 'Estado',
      invoices: 'Facturas',
    },
    filters: {
      months: {
        '01': 'Enero',
        '02': 'Febrero',
        '03': 'Marzo',
        '04': 'Abril',
        '05': 'Mayo',
        '06': 'Junio',
        '07': 'Julio',
        '08': 'Agosto',
        '09': 'Septiembre',
        10: 'Octubre',
        11: 'Noviembre',
        12: 'Diciembre',
      },
    },
    tableHeader: {
      employee: 'Empleado',
      program: 'Programa',
      date: 'Fecha',
      amount: 'Monto',
      download: 'Descargar',
    },
    tableBody: {
      markAsPaidBtn: 'Marcar como pagada',
    },
  },
  readings: {
    editTitle: {
      label: 'Título e imagen para la lectura',
    },
    blockTypes: {
      block: 'Contenido en bloque',
      heading1: 'Título 1',
      heading2: 'Título 2',
      heading3: 'Título 3',
      text: 'Bloque de texto',
      image: 'Imagen',
      bulletList: 'Lista con viñetas',
      divider: 'Separador',
    },
    editor: {
      label: 'Editor de bloques',
      description: 'Seleccione el tipo de bloque para ingresarlo a la lectura y modificar su contenido.',
      configLabel: 'Configurar el bloque',
    },
    preview: {
      emptyState: 'La vista previa de tu lectura comienza aquí.',
    },
  },
  shorVideos: {
    createBtn: 'Crear cápsula de video',
    emptyState: {
      title: '¡Aún no tienes cápsulas de video configuradas!',
      description: 'Inicia creando una cápsula de video para complementar el programa que cursará tu empleado',
    },
  },
  eviePrograms: {
    createBtn: 'Crear programa para Evie',
    emptyState: {
      title: '¡Aún no tienes programas de Evie configurados!',
      description: 'Inicia creando un programa de Evie para la valoración de la mujer',
    },
    steps: {
      description: 'Descripción general',
      levels: 'Niveles',
      additionalContent: 'Contenido adicional',
    },
    detail: {
      title: 'Crea tu programa',
      editorLabel: 'Agrega una descripción del programa',
    },
    levels: {
      title: 'Personaliza cada nivel de tu programa, agrega los que sean necesarios',
      addLevelBtn: 'Añadir Nivel',
      addSessionBtn: 'Añadir Sesión',
      labelLevel: 'Nivel',
    },
    level: {
      title:
        'Personaliza cada sesión del nivel, añade ejercicios y Squeezes, define el tiempo de su duración, también puedes añadir las sesiones que sean necesarias.',
      labelSession: 'Sesión',
      exerciseSession: 'Nivel {{levelPosition}} / Sesión {{sessionPosition}}',
      infoTitle: 'Actualiza la información del nivel',
      blockTitle: 'Actualiza la información del bloque de ejercicios',
    },
    session: {
      addExBlockBtn: 'Añadir bloque de ejercicios',
      addTaskBlockBtn: 'Añadir bloque de tasks',
      addExercise: 'Agrega ejercicios a la sesión',
      addSqueeze: 'Agrega squeezes a la sesión',
    },
    warning: {
      levelTitle: '¿Seguro quieres eliminar el nivel?',
      sessionTitle: '¿Seguro quieres eliminar la sesión?',
      excerciseTitle: '¿Seguro quieres eliminar el bloque de ejercicios?',
    },
    additionalContent: {
      title: 'Personaliza cada nivel de tu programa, agrega el contenido adicional que sea necesario',
      titleLevel: 'Agrega contenido adicional al nivel',
      selectedItems: {
        label: 'Resumen de la selección',
        count: '{{length}} ejercicios',
      },
    },
    itemsHeader: {
      readings: {
        label: 'Lecturas',
        description:
          'Gestiona las lecturas del nivel, agrega las que quieras de acuerdo a las necesidades del programa',
      },
      audios: {
        label: 'Audios',
        description: 'Gestiona los audios del nivel, agrega las que quieras de acuerdo a las necesidades del programa',
      },
      shortVideos: {
        label: 'Cápsulas de video',
        description:
          'Gestiona las cápsulas de video del nivel, agrega las que quieras de acuerdo a las necesidades del programa',
      },
      exercises: {
        label: 'Ejercicios',
        description:
          'Gestiona los ejercicios del nivel, agrega los bloques que quieras de acuerdo a las necesidades del programa',
      },
      tasks: {
        label: 'Tasks',
        description:
          'Gestiona los Tasks del nivel, agrega los bloques que quieras de acuerdo a las necesidades del programa',
      },
    },
  },
  patient: {
    personalInfo: {
      btns: {
        book: 'Reservar una llamada',
      },
      booking: {
        title: 'Reservar una llamada',
        titleUpdate: 'Reprogramar llamada',
        description: 'Reserva una llamada y haz un seguimiento personalizado al programa de ejercicios de tu paciente',
      },
    },
  },
  squeezes: {
    createBtn: 'Crear squeeze',
    actions: {
      release: 'Relajar',
      hold: 'Sostener',
      squeeze: 'Contraer',
      rest: 'Descanso',
    },
    emptyState: {
      title: '¡Aún no tienes squeezes configurados!',
      description: 'Inicia configurando un squeeze para complementar los planes de tus pacientes.',
    },
  },
  evieOnboarding: {
    welcome: {
      title: 'Bienvenida <br /> a Evie',
      subtitle: 'Bienvenida a Evie',
      pathSelector: {
        content: 'Seleccione la condición por la cual desea consultar',
        conditions: {
          pregnancy: 'Embarazo',
          postpartum: 'Posparto',
          incontinence: 'Incontinencia',
          sexualPain: 'Otro',
        },
      },
    },
    infoScreen: {
      pregnancy: {
        title1: 'Prepárate para tu parto',
        description1:
          'Aprende a pujar, técnicas de respiración, posturas de parto y reduce el riesgo de lesiones, sea parto vaginal o cesárea.',
        title2: 'Controla tu suelo pélvico',
        description2:
          'Recibe ejercicios pélvicos personalizados para prevenir o tratar escapes de orina, dolor sexual, estreñimiento y muchos más.',
        title3: 'Conecta con tu cuerpo',
        description3:
          'Accede a fisioterapeutas expertas en salud pélvica femenina quienes te guiarán a sentirte fuerte y preparada para el parto.',
      },
      postpartum: {
        title1: 'Recuperación posparto',
        description1:
          '¿Pérdidas al estornudar? ¿dolor al tener relaciones? ¿sientes que algo "se te cae" ahí abajo?. Tu cuerpo posparto merece atención.',
        title2: 'Controla tu suelo pélvico',
        description2:
          'Recupera tus músculos pélvicos y abdomen para volver con confianza a tus actividades, desde el ejercicio hasta disfrutar tu vida íntima.',
        title3: 'Conecta con tu cuerpo',
        description3:
          'Recibe guía de fisioterapeutas expertas en salud pélvica femenina y empieza a sentirte nuevamente imparable.',
      },
      pain: {
        title1: 'Alivia tus síntomas',
        description1:
          '¿Pérdidas al estornudar? ¿dolor al tener relaciones? ¿sientes que algo "se te cae" ahí abajo?. Tu cuerpo merece atención.',
        title2: 'Controla tu suelo pélvico',
        description2:
          'Recibe ejercicios pélvicos personalizados para prevenir o tratar escapes de orina, dolor sexual, estreñimiento y muchos más.',
        title3: 'Conecta con tu cuerpo',
        description3:
          'Accede a fisioterapeutas expertas en salud pélvica femenina quienes te guiarán a sentirte fuerte, segura y plena otra vez.',
      },
    },
    userName: '¿Cuál es tu nombre?',
    quizStarter: {
      title1: 'Sobre tu embarazo',
      title2: 'Sobre tu posparto',
      title3: 'Sobre tu salud pélvica',
      description:
        'Responde las siguientes preguntas con la mayor claridad, usaremos tus respuestas para brindarte la mejor atención y tratamiento. Tu información es confidencial, todo lo que compartas se queda aquí.',
      button: 'Siguiente',
    },
    signup: {
      title: '¿A dónde deberíamos enviar tu plan personalizado?',
      description: 'Ingresa un correo electrónico:',
      description1: 'Ingresa una contraseña:',
      emailPlaceholder: 'ejemplo@gmail.com',
      passwordPlaceholder: 'Contraseña',
      submitButton: 'Siguiente',
      privacy: 'No compartiremos tus datos personales con nadie',
    },
    perkScreen: {
      title1: 'Embarazo y parto consciente',
      title2: 'Recuperación posparto',
      title3: 'Salud pélvica',
      description1: 'Tu programa personalizado de embarazo',
      description2: 'Tu programa personalizado de posparto',
      description3: 'Tu programa personalizado de salud pélvica',
      button: 'Empieza tu programa',
      perks: {
        physio: 'Valoración con fisioterapeutas',
        chat: 'Chat privado con expertas en salud',
        exercises: 'Ejercicios de coordinación pélvica',
        routines: 'Rutinas personalizadas para cada día',
        audio: 'Lecturas, audios y cápsulas interactivas',
      },
    },
    planScreen: {
      title: 'Un plan que se adapta a ti',
      button: 'Activar mi plan',
      testimonials: {
        title: 'Mujeres como tú',
        pregnancy: {
          meli: {
            content:
              'Logré tener mi parto soñado, sin epidural y sin ninguna complicación. Con el programa de preparación para el parto consciente, llegué a la clínica dilatada en 8, lista para parir.',
            author: 'Meli, 33 años',
          },
          angie: {
            content:
              'Tenía miedo a una segunda cesárea,  pero gracias a los ejercicios, mi bebé nació en 5 pujos por parto vaginal, además de tratar todos mis síntomas.',
            author: 'Angie, 32 años',
          },
          kathe: {
            content:
              'Me sentí segura, en control y confianza con mi cuerpo durante el parto. Llegué dilatada en 7, con buen manejo del dolor. ¡De las experiencias mas positivas!',
            author: 'Katherine, 28 años',
          },
          evi: {
            content:
              'Durante mi embarazo, mi abdomen estaba débil, sufrí mucho dolor de espalda y cadera. Seguí los ejercicios, recuperé la fuerza y mi bebé nació vaginalmente en solo 4 horas.',
            author: 'Evi, 31 años',
          },
        },
        postpartum: {
          angie: {
            content:
              'Experimenté perdidas de orina al estornudar con mi segundo embarazo. Con los ejercicios pélvicos  y guía de mi terapeuta, logré reducir las perdidas en solamente dos  semanas.',
            author: 'Angie, 32 años',
          },
          caro: {
            content:
              'Después de mi embarazo, mi abdomen estaba débil y los músculos estaban separados. Seguí los ejercicios y recuperé la firmeza y fuerza que había perdido.',
            author: 'Caro, 37 años',
          },
          tati: {
            content:
              'Mi recuperación posparto fué más rápido de lo que pensaba, retomé relaciones con mi pareja sin dolor y con mucha confianza.',
            author: 'Tatiana, 30 años',
          },
        },
        pain: {
          luisa: {
            content:
              'Hoy incluí saltos en mi rutina de ejercicios y no tuve escapes de orina. Es la primera vez que esto me pasa en muchos años. ¡WOW!',
            author: 'Luisa, 38 años',
          },
          pao: {
            content:
              'Gracias al programa se salud sexual de Evie, mi dolor durante la penetración al tener sexo mejoró un 90%, me siento mas segura de mí misma. ¡Gracias!',
            author: 'Paola, 32 años',
          },
          anna: {
            content:
              'Solía presentar infecciones urinarias frecuentes y pensaba que esto era normal. Hace 3 meses no he tenido mas infecciones, además reconocí mi cuerpo y aprendí nuevos hábitos pélvicos.',
            author: 'Anna, 32 años',
          },
        },
      },
      offering: {
        monthly: 'Mensual',
        annual: 'Anual',
        trial: 'Gratis 7 días',
        discount: 'Descuento del {{percentage}}%',
        access: 'Completo acceso al programa',
        billed: {
          monthly: 'Paga mensualmente',
          annual: 'Paga anualmente',
        },
        warranty: '<strong>GARANTÍA SIN RIESGO:</strong> Cancela en cualquier momento sin cargo total.',
        terms:
          'Te estás suscribiendo a un plan mensual o anual en la aplicación <strong>Evie Health</strong> después de tu prueba gratuita de <strong>7 días.</strong> Una vez finalizado el período de prueba, tu plan seleccionado se renovará automáticamente, y el cargo correspondiente se aplicará a la tarjeta que proporcionaste. Puedes cancelar tu suscripción en cualquier momento contactando a nuestro equipo por correo electrónico a <strong>info@hievie.com</strong>. El cargo aparecerá en tu factura como Healthphy OÜ.',
      },
    },
    trialScreen: {
      title: 'Cómo funciona la prueba gratuita',
      steps: {
        stepOne: {
          title: 'Completa la prueba',
          content: 'Has creado tu perfil con éxito',
        },
        stepTwo: {
          title: 'Hoy: Obtenga acceso inmediato',
          content:
            'Desbloquea el acceso completo a tu programa personalizado Evie de forma gratuita y descubre cómo puede cambiar tu vida',
        },
        stepThree: {
          title: 'Día 5: Recordatorio de prueba',
          content: 'Te enviaremos un correo electrónico/notificación.',
        },
        stepFour: {
          title: 'Día 7: Termina la prueba gratuita',
          content: 'Tu subscripción empezará el',
        },
      },
    },
    loadingScreen: {
      title: 'Creando tu plan personalizado',
      content:
        '¡Un momento por favor! Estamos analizando tus respuestas para diseñar un plan hecho a tu medida. No tomará mucho tiempo. ¡Gracias por tu paciencia!',
    },
    thankyouScreen: {
      title: 'Tu membresía de Evie ya está activa',
      content: 'Para comenzar tu camino:',
      btn: 'Descarga ahora',
      steps: {
        one: '1. Descarga la app de Evie.',
        two: '2. Inicia sesión en tu cuenta.',
        qr: 'O escanea el código QR que está abajo:',
      },
    },
    setPasswordScreen: {
      title: '✅ ¡Tu prueba gratuita ha comenzado!',
      description: 'Establece tu contraseña para acceder a tu cuenta en cualquier momento.',
      btn: 'Guardar y continuar',
      placeholder: {
        password: 'Nueva contraseña',
        confirmPassword: 'Confirmar contraseña',
      },
    },
    indexScreen: {
      header: {
        btn: 'Ver mi plan',
      },
      banner: {
        percentage: 'Ahorra',
        deadline: 'La oferta termina en:',
      },
      hero: {
        pregnancy: {
          title: '"Logré tener mi parto soñado',
          quote: 'sin epidural y sin ninguna complicación',
          description:
            'Con el programa de <strong>preparación para el parto consciente,</strong> llegué a la clínica dilatada en 8, lista para parir".',
          userName: 'Mely Polo, 33 años',
        },
        postpartum: {
          title: '"Mi recuperación posparto fué más rápida',
          quote: 'Retomé las relaciones con mi pareja',
          description:
            'sin dolor y con mucha confianza, gracias al programa de <strong>recuperación</strong> volví a mi rutina antes de lo que yo me esperaba".',
          userName: 'Tatiana, 32 años',
        },
        pain: {
          title: '"Conseguí reducir las pérdidas de orina',
          quote: 'déspues de dos semanas en el programa',
          description:
            'de <strong>incontinencia</strong>. Mediante los ejercicios pélvicos y la orientación de mi fisioterapeuta, <strong>logré reirme y saltar sin miedo</strong>".',
          userName: 'Angie, 33 años',
        },
      },
      goals: {
        pregnancy: {
          nowCard: {
            tag: 'Ahora',
            key1: 'Disfunción pélvica',
            value1: 'Moderada',
            key2: 'Nivel de preparación',
            value2: 'Bajo',
            key3: 'Confianza al parto',
            value3: 'Media',
          },
          goalCard: {
            tag: 'Tu meta',
            key1: 'Disfunción pélvica',
            value1: 'Ningún síntoma',
            key2: 'Nivel de preparación',
            value2: 'Alto',
            key3: 'Confianza al parto',
            value3: 'Alta',
          },
        },
        postpartum: {
          nowCard: {
            tag: 'Ahora',
            key1: 'Disfunción pélvica',
            value1: 'Moderada',
            key2: 'Recuperación',
            value2: 'Lenta',
            key3: 'Deseo sexual',
            value3: 'Bajo',
          },
          goalCard: {
            tag: 'Tu meta',
            key1: 'Disfunción pélvica',
            value1: 'Ningún síntoma',
            key2: 'Recuperación',
            value2: 'Rápida',
            key3: 'Deseo sexual',
            value3: 'Alto',
          },
        },
        pain: {
          nowCard: {
            tag: 'Ahora',
            key1: 'Disfunción pélvica',
            value1: 'Moderada',
            key2: 'Escapes de orina',
            value2: 'Moderados',
            key3: 'Estima y seguridad',
            value3: 'Baja',
          },
          goalCard: {
            tag: 'Tu meta',
            key1: 'Disfunción pélvica',
            value1: 'Ningún síntoma',
            key2: 'Escapes de orina',
            value2: 'Ninguno',
            key3: 'Estima y seguridad',
            value3: 'Alta',
          },
        },
      },
      benefits: {
        pregnancy: {
          title: '¿Que aprenderás en este programa?',
          card1: 'Ejercicios para activar y flexibilizar el suelo pélvico.',
          card2: 'Orientación sobre posturas de parto seguras y métodos de pujo eficaces.',
          card3: 'Técnicas de respiración para aliviar el dolor y reducir riesgo de desgarros durante el parto.',
          card4: 'Pautas para crear tu plan de parto y tener una experiencia positiva.',
        },
        postpartum: {
          title: '¿Que aprenderás en este programa?',
          card1: 'Ejercicios para activar y flexibilizar el suelo pélvico.',
          card2: 'Orientación sobre como aumentar tu libido y retomar tu vida sexual.',
          card3: 'Técnicas para aliviar el dolor y reducir síntomas urinarios y/o defecatorios.',
          card4: 'Recomendaciones para el reinicio de la actividad deportiva con seguridad.',
        },
        pain: {
          title: '¿Que aprenderás en este programa?',
          card1: 'Ejercicios para activar y flexibilizar el suelo pélvico.',
          card2: 'Orientación sobre como aumentar tu libido y retomar tu vida sexual.',
          card3: 'Técnicas para aliviar el dolor y reducir síntomas urinarios y/o defecatorios.',
          card4: 'Recomendaciones para el reinicio de la actividad deportiva con seguridad.',
        },
      },
      infoData: {
        pregnancy: {
          title: 'Prepara tu cuerpo para el parto',
          qoute: 'Siéntete segura y preparada',
          content: 'para dar la bienvenida a tu bebé.',
        },
        postpartum: {
          title: 'Recupera tu cuerpo déspues del parto',
          qoute: 'Siéntete segura y preparada',
          content: 'para disfrutarte como mujer.',
        },
        pain: {
          title: 'Recupera tu cuerpo déspues del parto',
          qoute: 'Siéntete segura y preparada',
          content: 'para disfrutarte como mujer.',
        },
      },
      programContent: {
        pregnancy: {
          title: '¿Qué incluye?',
          card1: '<strong>Valoración inicial</strong> de 45 minutos con tu fisioterapeuta experta en salud femenina.',
          card2: '<strong>Ejercicios pélvicos</strong> personalizadas para cada día.',
          card3: '<strong>Contenido educativo</strong> y acceso a comunidad de mujeres.',
          card4: '<strong>Acompañamiento</strong> de tu fisio en todo el proceso y chat 24/7.',
        },
        postpartum: {
          title: '¿Qué incluye?',
          card1: '<strong>Valoración inicial</strong> de 45 minutos con tu fisioterapeuta experta en salud femenina.',
          card2: '<strong>Ejercicios pélvicos</strong> personalizadas para cada día.',
          card3: '<strong>Contenido educativo</strong> y acceso a comunidad de mujeres.',
          card4: '<strong>Acompañamiento</strong> de tu fisio en todo el proceso y chat 24/7.',
        },
        pain: {
          title: '¿Qué incluye?',
          card1: '<strong>Valoración inicial</strong> de 45 minutos con tu fisioterapeuta experta en salud femenina.',
          card2: '<strong>Ejercicios pélvicos</strong> personalizadas para cada día.',
          card3: '<strong>Contenido educativo</strong> y acceso a comunidad de mujeres.',
          card4: '<strong>Acompañamiento</strong> de tu fisio en todo el proceso y chat 24/7.',
        },
      },
      infoData1: {
        pregnancy: {
          label1: 'Llevamos más de',
          label2: '5000 sesiones virtuales',
          label3: 'impactando a más de',
          label4: '1000 mujeres',
          label5: 'Nivel de satisfacción',
        },
        postpartum: {
          label1: 'Llevamos más de',
          label2: '5000 sesiones virtuales',
          label3: 'impactando a más de',
          label4: '1000 mujeres',
          label5: 'Nivel de satisfacción',
        },
        pain: {
          label1: 'Llevamos más de',
          label2: '5000 sesiones virtuales',
          label3: 'impactando a más de',
          label4: '1000 mujeres',
          label5: 'Nivel de satisfacción',
        },
      },
    },
    testResultsScreen: {
      title: 'Tu resultado Disfunción pélvica:',
      result: 'Moderada',
      level: 'Nivel alta 7.5',
      description:
        '<strong>Una disfunción del suelo pélvico (DPS)</strong> ocurre cuando los músculos de esta zona están débiles, tensos o descoordinados.',
      symptoms: {
        sexual: {
          label: 'Satisfacción sexual',
          level: 'Debajo de lo normal',
        },
        urinary: {
          label: 'Síntomas urinarios',
          level: 'Debajo de lo normal',
        },
        bowel: {
          label: 'Función intestinal',
          level: 'Promedio',
        },
      },
    },
  },
  inputValidation: {
    required: 'Campo requerido',
    email: 'Correo electrónico inválido',
    password: 'La contraseña debe tener al menos 8 caracteres, una mayúscula y un número',
    match: 'Las contraseñas deben coincidir',
  },
  assets: {
    createTitle: 'Crea un archivo de multimedia',
    selectTitle: 'Selecciona archivo de multimedia',
    emptyState: {
      title: 'Aún no tienes archivos de multimedia creados!',
      content: 'Inicia creando un archivo de multimedia para la configuración de tus proyectos',
    },
    btn: 'Crear',
  },
  alert: {
    assignProgram: {
      title: '¿Estás seguro que deseas asignar este programa?',
      description: 'Este será el nuevo programa con el que tu usuario interactuará en la aplicación móvil',
    },
  },
  evieApp: {
    programTitle: 'Tu programa personal',
    bottomNav: {
      home: 'Inicio',
      program: 'Mi programa',
      profile: 'Configuración',
    },
    programInfoCard: {
      title: 'Tu programa personalizado',
      content: 'Descúbrelo y comienza hoy',
    },
    seeMore: {
      exLabel: 'Ejercicios',
      weekLabel: 'Semana 1',
      actionLabel: 'Ver mas',
    },
    dailyExerciseCard: {
      title: 'Ejercicios diarios',
      content: 'Fortalece tu suelo pélvico con nuestras sesiones guiadas.',
      guideLabel: 'Sessión guiada',
      timeLabel: '5 minutos',
    },
    goPlusCard: {
      title: '¡Hazte premium!',
      content: 'para que disfrutes tu programa personalizado',
    },
    contentCard: {
      readingLabel: 'Lectura',
      capsuleLabel: 'Cápsula de video',
      audioLabel: 'Audio',
    },
  },
};
