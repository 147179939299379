import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { isEmpty, pathOr } from 'ramda';
import { useMutation } from '@apollo/react-hooks';
import Loader from 'react-loader-spinner';

import { GET_BUILDER_PROGRAMS } from 'apollo/queries';
import { UPDATE_CURRENT_PROGRAM } from 'apollo/mutations';
import { useAuthQuery, useFormInput } from 'utils/hooks';
import { useToggle } from 'utils/hooks/useToggle';
import showNotification from 'utils/showNotification';

import Icon from 'components/Icon';
import SolidButton from 'components/SolidButton';
import SearchFilter from 'components/SearchFilter';
import AlertModal from 'components/AlertModal';
import { NoPatientsFound as NoProgramsFound } from 'components/EmptyState';

const UserPrograms = ({ patient, refetchPatient }) => {
  const { id: patientId, patientPrograms = [] } = patient;

  const { t } = useTranslation();
  const { loading = false, data = {}, refetch = () => {} } = useAuthQuery(GET_BUILDER_PROGRAMS);
  const { builderPrograms: allPrograms = [] } = data;
  const [updateCurrentProgram] = useMutation(UPDATE_CURRENT_PROGRAM);

  const [eviePrograms, setEviePrograms] = useState([]);
  const [selectedProgram, setSelectedProgram] = useState({});
  const [displayAlert, setDisplayAlert] = useToggle();
  const [spinner, setSpinner] = useToggle();

  const searchPrograms = useFormInput('');
  const { value = '' } = searchPrograms;

  useEffect(() => {
    let temporal = allPrograms;
    if (value) {
      temporal = allPrograms.filter((program) => {
        const { title, description } = program;
        return `${title}-${description}`.toLowerCase().includes(value.toLowerCase());
      });
    }
    setEviePrograms(temporal);
  }, [allPrograms, value]);

  const handleUpdateProgram = async (values) => {
    setSpinner();
    const response = await updateCurrentProgram({
      variables: { ...values },
    });

    const errors = pathOr([], ['data', 'updateCurrentProgram', 'errors'])(response);
    const emptyErrors = isEmpty(errors);

    setSpinner();

    showNotification({
      type: emptyErrors ? 'success' : 'error',
      messages: emptyErrors ? ['Programa asignado exitósamente'] : errors,
    });

    if (emptyErrors) {
      refetch();
      refetchPatient();
      setDisplayAlert();
    }
  };

  const handleSelectProgram = (currentProgram, isActive) => {
    if (isActive) {
      return;
    }
    setSelectedProgram(currentProgram);
  };

  const activePrograms = patientPrograms.filter((program) => program.status === 'active');
  const modalData = {
    open: displayAlert,
    onCancelAction: setDisplayAlert,
    onConfirmAction: () => handleUpdateProgram({ userId: patientId, programId: selectedProgram.id }),
    title: t('alert.assignProgram.title'),
    description: t('alert.assignProgram.description'),
    labelConfirm: t('button.assign'),
    category: 'add',
  };

  return (
    <>
      <div className="w-100">
        <div className="patient__title">
          <div className="patient__title_text">
            <Icon name="plans" className="icon" />
            <p className="title">Programas asignados</p>
          </div>
        </div>
        <ul className="current-user-programs">
          {patientPrograms
            .sort((a, b) => {
              if (a.status > b.status) {
                return 1;
              }
              if (a.status < b.status) {
                return -1;
              }
              return 0;
            })
            .map((activeProgram) => {
              const { program, currentLevelNumber: level, currentSessionNumber: session, status } = activeProgram;
              const { id = '', title = '' } = program;
              return (
                <li key={id} className="patient-plans__title">
                  <span style={{ color: '#616261', fontSize: 14 }}>
                    {title} - sesión {session}, nivel {level}
                  </span>{' '}
                  {status === 'active' ? <strong> | {status}</strong> : null}
                </li>
              );
            })}
        </ul>
        <SearchFilter searchItems={searchPrograms} placeholder={t('searchLabel.program')} />
        {isEmpty(eviePrograms) ? (
          <NoProgramsFound isModal />
        ) : (
          <div className="patient__tab_content evie-programs">
            {eviePrograms.map((program) => {
              const { id = '', title = '', programLevels = [] } = program;
              const isActive = activePrograms.map((currProgram) => currProgram.program.id).includes(id);
              const isSelected = selectedProgram && selectedProgram.id === id;
              return (
                <button
                  key={id}
                  type="button"
                  className={`patient__tab_content evie-programs-card ${isSelected ? 'selected-program' : ''}`}
                  onClick={() => handleSelectProgram(program, isActive)}
                >
                  <span>
                    {title}
                    {isActive ? <span style={{ color: '#65dfb6' }}> | active</span> : null}
                  </span>
                  <span>{programLevels.length < 2 ? '1 Nivel' : `${programLevels.length} Niveles`}</span>
                </button>
              );
            })}
          </div>
        )}
        <SolidButton
          disabled={isEmpty(selectedProgram)}
          className={`ml-auto ${isEmpty(selectedProgram) ? 'disabled' : ''}`}
          onClick={setDisplayAlert}
        >
          Asignar programa
        </SolidButton>
      </div>
      <AlertModal {...modalData} />
      <Loader
        className="spinner"
        type="ThreeDots"
        color="#495fd7"
        height={100}
        width={100}
        visible={loading || spinner}
      />
    </>
  );
};

UserPrograms.defaultProps = {
  refetchPatient: () => {},
};

UserPrograms.propTypes = {
  patient: PropTypes.shape({
    id: PropTypes.string,
    patientPrograms: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
  refetchPatient: PropTypes.func,
};

export default UserPrograms;
