import React from 'react';
import PropTypes from 'prop-types';
import { FaStar } from 'react-icons/fa';
import { useTranslation, Trans } from 'react-i18next';

import pregnancyShape from 'assets/images/hero-index-pr-img.webp';
import heart from 'assets/images/multi-heart.svg';
import leftLine from 'assets/images/left-line.webp';
import rightLine from 'assets/images/right-line.webp';

import HeaderIndex from '../HeaderIndex';
import HeroIndex from '../HeroIndex';
import SerenadeBg from '../SerenadeBg';
import Goals from '../Goals';
import Offerings from '../Offerings';
import Testimonials from '../Testimonials';
import Benefits from '../Benefits';
import InfoData from '../InfoData';
import ProgramContent from '../ProgramContent';

const PregnancyIndex = ({ offerings, selectedOffer, setSelectedOffer, path, isDiscount, identifier, queryEmail }) => {
  const { t } = useTranslation();

  return (
    <section className="index-screen">
      <HeaderIndex isDiscount={isDiscount} identifier={identifier} />
      <HeroIndex isDiscount={isDiscount}>
        <div className="index-screen__heroimage">
          <img src={pregnancyShape} alt="silhouette of pregnant woman" className="index-screen__heroimage_img" />
        </div>
        <h2 className="index-screen__hero_title">{t('evieOnboarding.indexScreen.hero.pregnancy.title')}</h2>
        <p className="index-screen__hero_quote">{t('evieOnboarding.indexScreen.hero.pregnancy.quote')}</p>
        <p className="index-screen__hero_description">
          <Trans components={{ strong: <strong /> }}>evieOnboarding.indexScreen.hero.pregnancy.description</Trans>
        </p>
        <p className="index-screen__hero_user">{t('evieOnboarding.indexScreen.hero.pregnancy.userName')}</p>
      </HeroIndex>
      <SerenadeBg>
        <Goals path={path} />
        <section id="offerings" className="index-screen__offerings">
          <div className="info-screen__footer_icon index-view">
            <img className="multi-heart-icon" src={heart} alt="heart" />
          </div>
          <h2 className="info-screen__footer_title plan-screen">{t('evieOnboarding.planScreen.title')}</h2>
          <Offerings
            offerings={offerings}
            selectedOffer={selectedOffer}
            setSelectedOffer={setSelectedOffer}
            isDiscount={isDiscount}
            identifier={identifier}
            queryEmail={queryEmail}
          />
        </section>
      </SerenadeBg>
      <Testimonials path={path} />
      <Benefits path={path} />
      <InfoData>
        <p className="index-screen__infodata_title">{t('evieOnboarding.indexScreen.infoData.pregnancy.title')}</p>
        <span className="index-screen__infodata_bubble">
          {t('evieOnboarding.indexScreen.infoData.pregnancy.qoute')}
        </span>
        <p className="index-screen__infodata_footer">{t('evieOnboarding.indexScreen.infoData.pregnancy.content')}</p>
      </InfoData>
      <ProgramContent path={path} />
      <section className="index-screen__rate">
        <div className="index-screen__programcontent_whitewavebg" />
        <div className="index-screen__programcontent_whitewavebg bottom" />
        <InfoData>
          <img src={leftLine} alt="design line" className="index-screen__rate_line" />
          <img src={rightLine} alt="design line" className="index-screen__rate_line right" />
          <p className="index-screen__rate_title">{t('evieOnboarding.indexScreen.infoData1.pregnancy.label1')}</p>
          <p className="index-screen__rate_label">{t('evieOnboarding.indexScreen.infoData1.pregnancy.label2')}</p>
          <p className="index-screen__rate_title">{t('evieOnboarding.indexScreen.infoData1.pregnancy.label3')}</p>
          <span className="index-screen__rate_bubble">
            {t('evieOnboarding.indexScreen.infoData1.pregnancy.label4')}
          </span>
          <div className="index-screen__rate_card">
            <div className="index-screen__rate_cardtitle">
              <p className="index-screen__rate_level">{t('evieOnboarding.indexScreen.infoData1.pregnancy.label5')}</p>
              <p className="index-screen__rate_points">4.8/5</p>
            </div>
            <div className="index-screen__rate_stars">
              <div className="index-screen__rate_star">
                <FaStar />
              </div>
              <div className="index-screen__rate_star">
                <FaStar />
              </div>
              <div className="index-screen__rate_star">
                <FaStar />
              </div>
              <div className="index-screen__rate_star">
                <FaStar />
              </div>
              <div className="index-screen__rate_star">
                <FaStar />
              </div>
            </div>
          </div>
        </InfoData>
        <div className="index-screen__rate_creamwavebg" />
        <div className="index-screen__rate_creamwavebg bottom" />
      </section>
      <section className="index-screen__offerings">
        <div className="info-screen__footer_icon index-view">
          <img className="multi-heart-icon" src={heart} alt="heart" />
        </div>
        <h2 className="info-screen__footer_title plan-screen">{t('evieOnboarding.planScreen.title')}</h2>
        <Offerings
          offerings={offerings}
          selectedOffer={selectedOffer}
          setSelectedOffer={setSelectedOffer}
          isDiscount={isDiscount}
          identifier={identifier}
          queryEmail={queryEmail}
        />
      </section>
    </section>
  );
};

PregnancyIndex.defaultProps = {
  selectedOffer: null,
  path: null,
  isDiscount: false,
  identifier: null,
  queryEmail: '',
};

PregnancyIndex.propTypes = {
  offerings: PropTypes.arrayOf(PropTypes.shape({ identifier: PropTypes.string.isRequired })).isRequired,
  selectedOffer: PropTypes.shape({ identifier: PropTypes.string.isRequired }),
  setSelectedOffer: PropTypes.func.isRequired,
  path: PropTypes.string,
  isDiscount: PropTypes.bool,
  identifier: PropTypes.string,
  queryEmail: PropTypes.string,
};

export default PregnancyIndex;
