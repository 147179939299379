import React from 'react';
import PropTypes from 'prop-types';

const SerenadeBg = ({ children }) => <div className="index-screen__serenadebg">{children}</div>;

SerenadeBg.propTypes = {
  children: PropTypes.node.isRequired,
};

export default SerenadeBg;
