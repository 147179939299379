import React from 'react';

import { IoIosArrowDropdown } from 'react-icons/io';

const ProgramContentBenefits = () => (
  <section className="program-content">
    <div className="program-content__benefits">
      <IoIosArrowDropdown /> <p>Te brindamos las herramientas y el apoyo necesarios</p>
    </div>
    <div className="program-content__benefits">
      <IoIosArrowDropdown /> <p>Te brindamos las herramientas y el apoyo necesarios</p>
    </div>
    <div className="program-content__benefits">
      <IoIosArrowDropdown /> <p>Te brindamos las herramientas y el apoyo necesarios</p>
    </div>
  </section>
);

export default ProgramContentBenefits;
