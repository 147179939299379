import React, { useState } from 'react';

import { HOME_STATUS } from 'components/evieApp/utils';

import Layout from 'components/evieOnboarding/Layout';
import BottomNav from 'components/evieApp/BottomNav';
import HomeContent from 'components/evieApp/HomeContent';
import ProgramInfo from 'components/evieApp/ProgramInfo';
import DailyExercises from 'components/evieApp/DailyExercises';
import TaskController from 'components/evieApp/TaskController';

import './evie-home.scss';

const EvieHome = () => {
  const [homeStatus, setHomeStatus] = useState(HOME_STATUS.IDLE);

  const handleChangeHomeStatus = (status) => {
    setHomeStatus(status);
  };

  return (
    <Layout>
      {homeStatus !== HOME_STATUS.EXERCISES && <BottomNav />}
      {homeStatus === HOME_STATUS.IDLE && <HomeContent changeHomeStatus={handleChangeHomeStatus} />}
      {homeStatus === HOME_STATUS.PROGRAM && (
        <ProgramInfo
          changeHomeStatus={() => {
            handleChangeHomeStatus(HOME_STATUS.IDLE);
          }}
        />
      )}
      {homeStatus === HOME_STATUS.EXERCISES && (
        <DailyExercises
          changeHomeStatus={() => {
            handleChangeHomeStatus(HOME_STATUS.IDLE);
          }}
        />
      )}
      {homeStatus === HOME_STATUS.TASK && <TaskController />}
    </Layout>
  );
};

export default EvieHome;
