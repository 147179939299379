import React from 'react';
import PropTypes from 'prop-types';

import { useLocalStorage } from 'utils/hooks/useLocalStorage';
import { CONDITIONS, KEYS } from '../utils/constants';

import { PregnancyProgramContent } from './PregnancyProgramContent';
import { PostpartumProgramContent } from './PostpartumProgramContent';
import { PainProgramContent } from './PainProgramContent';

const ProgramContent = ({ path }) => {
  const [selectedPath] = useLocalStorage(KEYS.PATH);

  switch (path || selectedPath) {
    case CONDITIONS.PREGNANCY:
      return <PregnancyProgramContent />;
    case CONDITIONS.POSTPARTUM:
      return <PostpartumProgramContent />;
    case CONDITIONS.INCONTINENCE:
    case CONDITIONS.SEXUAL:
      return <PainProgramContent />;
    default:
      return null;
  }
};

ProgramContent.defaultProps = {
  path: null,
};

ProgramContent.propTypes = {
  path: PropTypes.string,
};

export default ProgramContent;
