import React from 'react';
import PropTypes from 'prop-types';

import sadFace from 'assets/images/sad-face.webp';
import happyFace from 'assets/images/happy-face.webp';

const icons = {
  happy: happyFace,
  sad: sadFace,
};

export const GoalsCard = ({ children, icon }) => (
  <section className="index-screen__goalscard">
    <div className="index-screen__goalscard_iconcontainer">
      <img src={icons[icon]} alt="sad face" className="index-screen__goalscard_icon" />
    </div>
    {children}
  </section>
);

GoalsCard.propTypes = {
  children: PropTypes.node.isRequired,
  icon: PropTypes.string.isRequired,
};
