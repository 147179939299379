import React from 'react';
import PropTypes from 'prop-types';

import { FaSearch } from 'react-icons/fa';

import './search-filter.scss';

const SearchFilter = ({ onClick, searchItems, placeholder, patientsView, collapsed, widthModifier }) => (
  <section
    onClick={onClick}
    className={`
        search-filter search-filter--${collapsed} 
        search-filter--${widthModifier} 
      `}
  >
    {!patientsView && <input {...searchItems} placeholder={placeholder} />}
    <FaSearch />
  </section>
);

SearchFilter.defaultProps = {
  onClick: () => {},
  patientsView: false,
  collapsed: '',
  widthModifier: '',
};

SearchFilter.propTypes = {
  onClick: PropTypes.func,
  searchItems: PropTypes.shape({
    value: PropTypes.string,
    onChange: PropTypes.func,
  }).isRequired,
  placeholder: PropTypes.string.isRequired,
  patientsView: PropTypes.bool,
  collapsed: PropTypes.string,
  widthModifier: PropTypes.string,
};

export default SearchFilter;
