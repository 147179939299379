import React from 'react';
import { useTranslation } from 'react-i18next';

import { BenefitCard } from './BenefitCard';

export const PainBenefits = () => {
  const { t } = useTranslation();

  return (
    <section className="index-screen__benefits">
      <h2 className="index-screen__benefits_title">{t('evieOnboarding.indexScreen.benefits.pain.title')}</h2>
      <BenefitCard>
        <p className="index-screen__benefitcard_content">{t('evieOnboarding.indexScreen.benefits.pain.card1')}</p>
      </BenefitCard>
      <BenefitCard>
        <p className="index-screen__benefitcard_content">{t('evieOnboarding.indexScreen.benefits.pain.card2')}</p>
      </BenefitCard>
      <BenefitCard>
        <p className="index-screen__benefitcard_content">{t('evieOnboarding.indexScreen.benefits.pain.card3')}</p>
      </BenefitCard>
      <BenefitCard>
        <p className="index-screen__benefitcard_content">{t('evieOnboarding.indexScreen.benefits.pain.card4')}</p>
      </BenefitCard>
    </section>
  );
};
