import React from 'react';
import PropTypes from 'prop-types';

import { useLocalStorage } from 'utils/hooks/useLocalStorage';
import { CONDITIONS, KEYS } from '../utils/constants';

import { PregnancyGoalsCards } from './PregnancyGoalsCards';
import { PostpartumGoalsCards } from './PostpartumGoalsCards';
import { PainGoalsCards } from './PainGoalsCards';

const Goals = ({ path }) => {
  const [selectedPath] = useLocalStorage(KEYS.PATH);

  switch (path || selectedPath) {
    case CONDITIONS.PREGNANCY:
      return <PregnancyGoalsCards />;
    case CONDITIONS.POSTPARTUM:
      return <PostpartumGoalsCards />;
    case CONDITIONS.INCONTINENCE:
    case CONDITIONS.SEXUAL:
      return <PainGoalsCards />;
    default:
      return null;
  }
};

Goals.defaultProps = {
  path: null,
};

Goals.propTypes = {
  path: PropTypes.string,
};

export default Goals;
