import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { IoIosArrowForward } from 'react-icons/io';

const SeeMoreButton = ({ content, action }) => {
  const { t } = useTranslation();

  return (
    <section className="see-more">
      <h2 className="see-more__label">{content}</h2>
      <button type="button" onClick={action} className="see-more__btn">
        {t('evieApp.seeMore.actionLabel')}
        <IoIosArrowForward />
      </button>
    </section>
  );
};

SeeMoreButton.propTypes = {
  content: PropTypes.string.isRequired,
  action: PropTypes.func.isRequired,
};

export default SeeMoreButton;
