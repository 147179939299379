import React, { useState, useEffect, useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/react-hooks';
import { propOr, pathOr, isEmpty } from 'ramda';
import Loader from 'react-loader-spinner';
import { GET_INDIVIDUAL } from 'apollo/queries';
import { useAuthQuery } from 'utils/hooks';
import { useToggle } from 'utils/hooks/useToggle';
import showNotification from 'utils/showNotification';
import { FETCH_USER_BY_ROLE, GET_USER_BY_ROLE, PARAM_ID_BY_ROLE } from 'utils/constants';
import { getUserRole } from 'utils/getUserRole';
import { UPDATE_PATIENT_STATUS, UPDATE_BOOKING, BOOKING_TOKEN } from 'apollo/mutations';

import Surveys from './Surveys';
import Assessment from './Assessment';
import Calls from './Calls';
import Plans from './Plans';
import FollowUp from './FollowUp';
import ActivityLog from './ActivityLog';
import PersonalInformation from './PersonalInformation';
import UserPrograms from './UserPrograms';

import './patient.scss';

const tabs = ['Valoración', 'Programas', 'Datos de seguimiento', 'Actividad del paciente', 'Llamadas', 'Encuestas'];

const Patient = ({ changeRoute, selectedId, paramsId, onUpdateCurrentActive, targetTab, hasCalls, isIndividual }) => {
  const { userRole, isSuperClinic } = getUserRole();
  const [showSpinner, setSpinner] = useToggle();
  const [updatePatientStatus] = useMutation(UPDATE_PATIENT_STATUS);
  const [updateBooking] = useMutation(UPDATE_BOOKING);
  const [getCallToken] = useMutation(BOOKING_TOKEN);
  const [tab, setTab] = useState(0);

  const {
    loading,
    data: userData,
    refetch,
  } = useAuthQuery(isIndividual ? GET_INDIVIDUAL : FETCH_USER_BY_ROLE[userRole], {
    variables: {
      [isIndividual ? 'individualId' : PARAM_ID_BY_ROLE[userRole]]: paramsId || selectedId,
    },
  });
  const patient = propOr({}, isIndividual ? 'individual' : GET_USER_BY_ROLE[userRole])(userData);
  const assessment = propOr({}, 'assessment')(patient);
  const { id = '', name = '' } = { ...patient };

  useEffect(() => {
    refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedId, paramsId]);

  useLayoutEffect(() => {
    if (!(targetTab === 4 && !hasCalls)) {
      setTab(Number(targetTab || 0));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [targetTab]);

  useEffect(() => {
    if (selectedId) {
      setTab(0);
    }
  }, [selectedId]);

  const onUpdateStatus = async (onCloseModal) => {
    onCloseModal();
    const response = await updatePatientStatus({
      variables: {
        patientId: id,
      },
    });

    const errors = pathOr([], ['data', 'updatePatientStatus', 'errors'])(response);
    const emptyErrors = isEmpty(errors);
    showNotification({
      type: emptyErrors ? 'success' : 'error',
      messages: emptyErrors ? ['Se ha actualizado el paciente exitosamente.'] : errors,
    });
    if (emptyErrors) {
      refetch();
      onUpdateCurrentActive();
    }
  };

  const onUpdateBooking = async (variables, onCloseModal, setUpdating) => {
    setSpinner();
    const response = await updateBooking({ variables });

    const errors = pathOr([], ['data', 'updateBookingRequest', 'errors'])(response);
    const emptyErrors = isEmpty(errors);
    setSpinner();
    showNotification({
      type: emptyErrors ? 'success' : 'error',
      messages: emptyErrors ? ['La solicitud se ha actualizado exitosamente.'] : errors,
    });

    if (emptyErrors) {
      setUpdating(false);
      onCloseModal();
      refetch();
    }
  };

  const onJoinCall = async (bookingId, sessionId, info, setStoreData) => {
    if (!hasCalls) {
      showNotification({ type: 'info', messages: [`La clínica no cuenta con la característica de llamadas activada`] });
      return;
    }
    setSpinner();
    const { startAt } = { ...info };
    const response = await getCallToken({ variables: { bookingId } });
    const { data = {} } = { ...response };
    const { generateBookingToken = {} } = { ...data };
    const { errors = [], token = '', ready = false } = { ...generateBookingToken };
    const values = { isOpen: true, sessionId, token };
    const emptyErrors = isEmpty(errors);
    setSpinner();
    if (emptyErrors) {
      if (ready && !!token) {
        setStoreData('openCall', values);
      } else {
        showNotification({ type: 'info', messages: [`La llamada estará disponible el ${startAt}`] });
      }
    } else {
      showNotification({ type: 'error', messages: errors });
    }
  };

  const handleTabs = (index) => {
    if (!name && (index !== 0) & (index !== 4)) {
      return null;
    }
    return setTab(index);
  };

  const getPlansTab = () => {
    if (isIndividual) {
      return <UserPrograms patient={patient} refetchPatient={refetch} />;
    }
    return <Plans patient={patient} />;
  };

  return (
    <>
      {!loading && (
        <div className="patient-container">
          {!!id && (
            <PersonalInformation
              patient={patient}
              onUpdateStatus={onUpdateStatus}
              selectedId={selectedId}
              paramsId={paramsId}
              refetch={refetch}
            />
          )}
          <div className="patient-container__tabs">
            {tabs.map((item, index) => {
              const unactive = !name && (index !== 0) & (index !== 4);
              const disabled = index === 4 && !hasCalls;
              return (
                <button
                  type="button"
                  key={item}
                  className={`tab ${index === tab ? 'active' : ''} ${unactive ? 'unactive' : ''} ${
                    disabled ? 'disabled' : ''
                  }`}
                  onClick={() => !disabled && handleTabs(index)}
                >
                  {item}
                </button>
              );
            })}
            <div className="down"> </div>
          </div>
          <div className="patient__container">
            {tab === 0 && <Assessment patient={patient} changeRoute={changeRoute} assessment={assessment} />}
            {tab === 1 && !!name && getPlansTab()}
            {tab === 2 && !!name && <FollowUp patient={patient} setSpinner={setSpinner} refetch={refetch} />}
            {tab === 3 && !!name && <ActivityLog patient={patient} />}
            {tab === 4 && hasCalls && (
              <Calls patient={patient} onUpdateBooking={onUpdateBooking} onJoinCall={onJoinCall} />
            )}
            {tab === 5 && <Surveys patient={patient} refetch={refetch} setSpinner={setSpinner} />}
          </div>
        </div>
      )}
      <Loader
        type="ThreeDots"
        color="#495fd7"
        height={100}
        width={100}
        className="spinner"
        visible={loading || showSpinner}
      />
    </>
  );
};

Patient.propTypes = {
  changeRoute: PropTypes.func.isRequired,
  selectedId: PropTypes.string.isRequired,
  paramsId: PropTypes.string.isRequired,
  onUpdateCurrentActive: PropTypes.func.isRequired,
  targetTab: PropTypes.string.isRequired,
  hasCalls: PropTypes.bool.isRequired,
  isIndividual: PropTypes.bool.isRequired,
};

export default Patient;
