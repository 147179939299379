import React from 'react';
import PropTypes from 'prop-types';

import ContentCard from '../ContentCard';

const WeekContent = ({ changeHomeStatus }) => (
  <section className="week-content">
    <ContentCard content="capsule" titleProp changeHomeStatus={changeHomeStatus} />
    <ContentCard content="capsule" titleProp changeHomeStatus={changeHomeStatus} />
    <ContentCard content="reading" changeHomeStatus={changeHomeStatus} />
    <ContentCard content="audio" titleProp changeHomeStatus={changeHomeStatus} />
    <ContentCard content="audio" changeHomeStatus={changeHomeStatus} />
    <ContentCard content="capsule" titleProp changeHomeStatus={changeHomeStatus} />
  </section>
);

WeekContent.propTypes = {
  changeHomeStatus: PropTypes.func.isRequired,
};

export default WeekContent;
