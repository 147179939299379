import React from 'react';
import PropTypes from 'prop-types';
import { FaRegFileAlt } from 'react-icons/fa';
import { IoMdArrowBack } from 'react-icons/io';

import SolidButton from 'components/SolidButton';
import ProgramContentCard from '../ProgramContentCard';

const ProgramInfo = ({ changeHomeStatus }) => (
  <section className="program-info">
    <section className="program-info__topbar">
      <IoMdArrowBack onClick={changeHomeStatus} />
    </section>
    <header className="program-info__header">
      <div className="program-info__header_bg" />
      <div className="program-info__title">
        <div className="program-info__duration">
          <div className="program-info__duration_icon">
            <FaRegFileAlt />
          </div>
          <span>8 units</span>
        </div>
        <h2>Conscious childbirth</h2>
      </div>
    </header>
    <ProgramContentCard isOpen content="description" />
    <ProgramContentCard content="benefits" />
    <ProgramContentCard content="content" />
    <div className="program-info__startbtn">
      <SolidButton className="evie-survey">Start now</SolidButton>
    </div>
  </section>
);

ProgramInfo.propTypes = {
  changeHomeStatus: PropTypes.func.isRequired,
};

export default ProgramInfo;
