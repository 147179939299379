import React from 'react';
import { NavLink /* useHistory */ } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { FiCalendar, FiUser } from 'react-icons/fi';
import { RiLeafLine } from 'react-icons/ri';

import { ROUTES } from 'utils/constants';

import './bottom-nav.scss';

const BottomNav = () => {
  const { t } = useTranslation();

  /* const {
    location: { pathname },
  } = useHistory();
 */
  return (
    <nav className="bottom-nav">
      <NavLink to={ROUTES.EVIE_HOME} className="bottom-nav__link" activeClassName="selected">
        <FiCalendar className="bottom-nav__icon" />
        <span>{t('evieApp.bottomNav.home')}</span>
      </NavLink>

      <NavLink to={ROUTES.EVIE_MY_PROGRAM} className="bottom-nav__link" activeClassName="selected">
        <RiLeafLine className="bottom-nav__icon" />
        <span>{t('evieApp.bottomNav.program')}</span>
      </NavLink>

      <NavLink to={ROUTES.EVIE_PROFILE} className="bottom-nav__link" activeClassName="selected">
        <FiUser className="bottom-nav__icon" />
        <span>{t('evieApp.bottomNav.profile')}</span>
      </NavLink>
    </nav>
  );
};

export default BottomNav;
