import React, { useEffect } from 'react';
import { Formik, Form } from 'formik';
import Lottie from 'lottie-react';
import { Slide } from 'react-awesome-reveal';
import { useMutation } from '@apollo/react-hooks';
import { useTranslation } from 'react-i18next';
import { isEmpty, pathOr } from 'ramda';

import { UPDATE_PASSWORD } from 'apollo/mutations';
import { useValidateFields } from 'components/evieOnboarding/utils/useValidateFields';
import { useGetParams } from 'components/evieOnboarding/utils/useGetParams';
import { useLocalStorage } from 'utils/hooks/useLocalStorage';
import { useToggle } from 'utils/hooks/useToggle';
import { useTabSettings } from 'utils/hooks/useTabSettings';
import { KEYS } from 'components/evieOnboarding/utils/constants';
import { ROUTES } from 'utils/constants';

import signupLogo from 'assets/images/signup-logo.webp';
import heartLoader from 'assets/heart-loader.json';

import Layout from 'components/evieOnboarding/Layout';
import SolidButton from 'components/SolidButton';
import TextInput from 'components/Surveys/FormControlSurveys/FormComponents/TextInput';

import './evie-set-password.scss';

const EvieSetPassword = () => {
  useTabSettings('Evie', '/evie-favicon.ico');
  const { t } = useTranslation();
  const { setPassword } = useValidateFields();
  const { urlToken, push } = useGetParams();
  const [userToken] = useLocalStorage(KEYS.USER_TOKEN);
  const token = urlToken || userToken;

  const [spinner, setSpinner] = useToggle();

  useEffect(() => {
    if (!token) {
      push(ROUTES.EVIE_ONBOARDING);
    }
  }, []);

  const [updatePassword] = useMutation(UPDATE_PASSWORD, {
    context: {
      headers: {
        authorization: token ? `Bearer ${token}` : '',
        role: 'individual',
      },
    },
  });

  const handleSetPassword = async (values) => {
    setSpinner();
    const { password } = values;
    const response = await updatePassword({ variables: { password } });

    const setPasswordErrors = pathOr([], ['data', 'updateUserPassword', 'errors'])(response);
    const emptyErrors = isEmpty(setPasswordErrors);

    setSpinner();

    if (emptyErrors) {
      push(ROUTES.EVIE_DOWNLOAD);
    }
  };

  return (
    <>
      <Layout>
        <section className="setpassword-screen">
          <div className="sections__form setpassword-screen__form">
            <img className="setpassword-screen__form_logo" src={signupLogo} alt="Evie logo" />
            <h2 className="setpassword-screen__form_label">{t('evieOnboarding.setPasswordScreen.title')}</h2>
            <p className="setpassword-screen__form_description">{t('evieOnboarding.setPasswordScreen.description')}</p>
            <Formik
              initialValues={{ password: '', confirmPassword: '' }}
              validationSchema={setPassword}
              onSubmit={handleSetPassword}
            >
              {(formData) => {
                const isDisabledNext = !isEmpty(formData.errors);

                return (
                  <Form>
                    <div className="form-input setpassword-screen__form_input">
                      <Slide duration={300}>
                        <TextInput
                          validate
                          longmessage
                          control="password"
                          placeholder={t('evieOnboarding.setPasswordScreen.placeholder.password')}
                          formikProps={formData}
                          name="password"
                        />
                      </Slide>
                    </div>
                    <div className="form-input setpassword-screen__form_input">
                      <Slide duration={300}>
                        <TextInput
                          validate
                          control="password"
                          placeholder={t('evieOnboarding.setPasswordScreen.placeholder.confirmPassword')}
                          formikProps={formData}
                          name="confirmPassword"
                        />
                      </Slide>
                    </div>
                    <SolidButton
                      disabled={isDisabledNext}
                      type="submit"
                      className={`evie-survey setpassword-screen__form_nextbtn handle-sections ${
                        isDisabledNext ? 'evie-disabled' : ''
                      }`}
                    >
                      {t('evieOnboarding.setPasswordScreen.btn')}
                    </SolidButton>
                  </Form>
                );
              }}
            </Formik>
          </div>
        </section>
      </Layout>
      {spinner && (
        <div className="onboarding-layout__lottie">
          <Lottie animationData={heartLoader} loop style={{ width: 140, height: 110 }} />
        </div>
      )}
    </>
  );
};

export default EvieSetPassword;
