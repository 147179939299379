import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Purchases } from '@revenuecat/purchases-js';

import { useLocalStorage } from 'utils/hooks/useLocalStorage';
import { ENVIRONMENT } from 'utils/constants';
import { useEvieOnboardingContext } from '../EvieOnboardingProvider';
import { CONDITIONS, KEYS, IDENTIFIERS } from '../utils/constants';
import { useGetParams } from '../utils/useGetParams';
import {
  REACT_APP_ENV,
  REACT_APP_REVENUECAT_BILLING_PUBLIC_API_KEY,
  REACT_APP_REVENUECAT_BILLING_SANDBOX_API_KEY,
} from '../../../config';

import PregnancyIndex from '../PregnancyIndex';
import PostpartumIndex from '../PostpartumIndex';
import PainIndex from '../PainIndex';

export const getOfferIdentifier = ({ currentCountry, urlIdentifier, identifiers }) => {
  if (urlIdentifier) return urlIdentifier;
  if (currentCountry === 'US') return identifiers.US;
  return identifiers.FULL;
};

const OfferingIndex = ({ userId, path, queryEmail }) => {
  const { identifier } = useGetParams();
  const { country } = useEvieOnboardingContext();

  const [selectedPath] = useLocalStorage(KEYS.PATH);
  const [selectedId] = useLocalStorage(KEYS.USER_ID);
  const [discountIdentifier, setDiscountIdentifier] = useLocalStorage(KEYS.IDENTIFIER);

  const currentIdentifier = identifier || discountIdentifier;
  // eslint-disable-next-line prettier/prettier
  const isDiscount = [IDENTIFIERS.THIRTY, IDENTIFIERS.FIFTY, IDENTIFIERS.US_DISCOUNT, IDENTIFIERS.TWENTY].includes(
    currentIdentifier,
  );

  const [offeringList, setOfferingList] = useState([]);
  const [selectedOffer, setSelectedOffer] = useState(null);

  useEffect(() => {
    setDiscountIdentifier(identifier);
  }, [identifier]);

  useEffect(() => {
    const fetchOfferings = async () => {
      const apiKey =
        REACT_APP_ENV === ENVIRONMENT.DEVELOPMENT
          ? REACT_APP_REVENUECAT_BILLING_SANDBOX_API_KEY
          : REACT_APP_REVENUECAT_BILLING_PUBLIC_API_KEY;
      const offerIdentifier = getOfferIdentifier({
        currentCountry: country,
        urlIdentifier: currentIdentifier,
        identifiers: IDENTIFIERS,
      });
      try {
        await Purchases.configure(apiKey, userId || selectedId);
        const offerings = await Purchases.getSharedInstance().getOfferings();
        if (offerings.all[offerIdentifier].availablePackages.length !== 0) {
          setOfferingList(offerings.all[offerIdentifier].availablePackages);
        }
      } catch (error) {
        console.error('Error fetching offerings: ', error);
      }
    };
    if (userId || selectedId) {
      fetchOfferings();
    }
  }, [userId, selectedId, country]);

  useEffect(() => {
    if (offeringList.length > 0) {
      const findYearlyOffer = offeringList.find((offer) => offer.identifier === '$rc_annual');
      setSelectedOffer(findYearlyOffer);
    }
  }, [offeringList]);

  switch (path || selectedPath) {
    case CONDITIONS.PREGNANCY:
      return (
        <PregnancyIndex
          offerings={offeringList}
          selectedOffer={selectedOffer}
          setSelectedOffer={setSelectedOffer}
          path={path}
          isDiscount={isDiscount}
          identifier={currentIdentifier}
          queryEmail={queryEmail}
        />
      );
    case CONDITIONS.POSTPARTUM:
      return (
        <PostpartumIndex
          offerings={offeringList}
          selectedOffer={selectedOffer}
          setSelectedOffer={setSelectedOffer}
          path={path}
          isDiscount={isDiscount}
          identifier={currentIdentifier}
          queryEmail={queryEmail}
        />
      );
    case CONDITIONS.INCONTINENCE:
    case CONDITIONS.SEXUAL:
      return (
        <PainIndex
          offerings={offeringList}
          selectedOffer={selectedOffer}
          setSelectedOffer={setSelectedOffer}
          path={path}
          isDiscount={isDiscount}
          identifier={currentIdentifier}
          queryEmail={queryEmail}
        />
      );
    default:
      return null;
  }
};

OfferingIndex.defaultProps = {
  userId: null,
  path: null,
  queryEmail: '',
};

OfferingIndex.propTypes = {
  userId: PropTypes.string,
  path: PropTypes.string,
  queryEmail: PropTypes.string,
};

export default OfferingIndex;
