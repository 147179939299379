/* eslint-disable import/order */
import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Purchases, PurchasesError, ErrorCode } from '@revenuecat/purchases-js';

import { ROUTES } from 'utils/constants';
import { useLocalStorage } from 'utils/hooks/useLocalStorage';
import { KEYS } from '../utils/constants';
import { useGetParams } from '../utils/useGetParams';
import { handleTrackEvent } from '../utils/handleTrackEvent';

import SolidButton from 'components/SolidButton';
import OfferingCard from '../OfferingCard';
import Warranty from '../Warranty';

const Offerings = ({ offerings, selectedOffer, setSelectedOffer, isDiscount, identifier, queryEmail }) => {
  const { t } = useTranslation();
  const { push } = useGetParams();
  const [, setEntitlements] = useLocalStorage(KEYS.ENTITLEMENTS);
  const [email] = useLocalStorage(KEYS.USER_EMAIL);

  const purchasePackage = async (pkg, userEmail) => {
    handleTrackEvent('Payment intent');
    try {
      const purchaseResult = await Purchases.getSharedInstance().purchase({ rcPackage: pkg, customerEmail: userEmail });

      if (Object.keys(purchaseResult.customerInfo.entitlements.active).includes('Premium')) {
        setEntitlements('Premium');
        handleTrackEvent('Subscription success');
        push(ROUTES.EVIE_SET_PASSWORD);
      }
    } catch (error) {
      if (error instanceof PurchasesError && error.errorCode === ErrorCode.UserCancelledError) {
        console.error('purchased cancelled: ', error);
      } else {
        console.error('error', error);
      }
    }
  };

  return (
    <section className="plan-screen__offerings">
      {offerings.map((offer) => (
        <OfferingCard
          key={offer.identifier}
          offer={offer}
          setSelectedOffer={setSelectedOffer}
          selectedOffer={selectedOffer}
          isDiscount={isDiscount}
          identifier={identifier}
        />
      ))}
      <SolidButton
        className="evie-survey handle-sections"
        onClick={() => purchasePackage(selectedOffer, email || queryEmail)}
      >
        {t('evieOnboarding.planScreen.button')}
      </SolidButton>
      <Warranty />
    </section>
  );
};

Offerings.defaultProps = {
  selectedOffer: null,
  isDiscount: false,
  identifier: null,
  queryEmail: '',
};

Offerings.propTypes = {
  offerings: PropTypes.arrayOf(PropTypes.shape({ identifier: PropTypes.string.isRequired })).isRequired,
  selectedOffer: PropTypes.shape({ identifier: PropTypes.string.isRequired }),
  setSelectedOffer: PropTypes.func.isRequired,
  isDiscount: PropTypes.bool,
  identifier: PropTypes.string,
  queryEmail: PropTypes.string,
};

export default Offerings;
